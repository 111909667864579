/*
 * @Author: Glauber Funez
 * @Date:   2018-05-08 08:23:04
 * @Last Modified by:   Glauber Funez
 * @Last Modified time: 2018-05-09 17:46:25
 */

(function() {
    'use strict';

    angular
        .module('app')
        .factory('FactoryModalMotivo', FactoryModalMotivo);


    function FactoryModalMotivo($http, $q, $location, RC, $routeParams, API, $window, $timeout, $log, APINode, $mdDialog, motivoPropostaService, FactoryWebhook, negociosService, loadSaas, $rootScope) {

        var vm = this;

        // return function ⤵
        return {
            openModal: openModal
        };

        // set function ⤵        

        function mostrarTooltipAcao(id) {
            var left = $('.situacao_' + id).offset().left;
            var top = $('.situacao_' + id).offset().top;
            var width = $('.situacao_' + id).width();
            var height = $('.situacao_' + id).height();
            var width_t = $('.acao_' + id).width(); // largura tooltip
            $('.msg_' + id).html('Alterado com sucesso!<br>Clique novamente para desfazer.');
            $('.acao_' + id).css('left', ((width / 2) - (width_t / 2) + 15) + 'px');
            $('.acao_' + id).css('top', (height + 15) + 'px');
            $('.acao_' + id).fadeIn('fast');
        };

        function removerTooltipAcao(id) {
            $('.acao_' + id).fadeOut('fast');
        };

        function situacaoCompufacil(id) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                var dados = {
                    'saasid': vm.saasid,
                    'id_prop': id,
                    'user_hash': Cookies.get('crm_funil_hash')
                }
                negociosService.situacaoCompufacil(dados).then(function(response) {}, function(error) {
                    $log.error(error);
                });
            });
        }

        function venderLeadExactSales(id) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'id_prop': id
                }
                negociosService.venderLeadExactSales(dados).then(function(response) {
                    /*console.log("venderLeadExactSales");
                    console.log(response.data);*/
                }, function(error) {
                    $log.error(error);
                });
            });
        }

        function openModal(prop, $event) {
            var parentEl = angular.element(document.body);
            $mdDialog.show({
                parent: parentEl,
                targetEvent: $event,
                clickOutsideToClose: true,
                templateUrl: 'motivoProposta/template/motivoModal.html',
                locals: {
                    dadosProp: prop
                },
                controller: DialogModalMotivoController,
                controllerAs: 'vm',
            }).then(function(dados) {
                /*var mot_descricao = "<span style='font-size: 11px; line-height: 1.5;'><span class='destacar'>Motivo:</span> " + dados.motivo.mot_descricao + "</span>";

                $(".perdeu_" + prop.prop_id).tooltipster({
                    interactive: true,
                    trigger: 'hover',
                    maxWidth: 250,
                    minWidth: 250,
                    position: 'right',
                    theme: 'tooltipster-light',
                    contentAsHTML: true,
                    delay: 100,
                    content: mot_descricao
                });

                $timeout(function() {
                    $(".perdeu_" + prop.prop_id).tooltip('destroy');
                }, 200);

                $('.perdeu_' + prop.prop_id).toggleClass('marcado_perdeu');
                $('.ganhou_' + prop.prop_id).removeClass('marcado_ganhou');
                $('.ganhou_' + prop.prop_id).removeClass('botao_ganhar_marcado');
                $('.spinner_' + prop.prop_id).toggleClass('hide');
                $('.ganhou_' + prop.prop_id).toggleClass('hide');
                $('.perdeu_' + prop.prop_id).toggleClass('hide');
                mostrarTooltipAcao(prop.prop_id);
                $timeout(function() {
                    removerTooltipAcao(prop.prop_id);
                }, 4000);*/
                situacaoCompufacil(prop.prop_id);
                venderLeadExactSales(prop.prop_id);
                $rootScope.$broadcast('atualizar_negociacoes', dados);
            }, function() {
                $('.spinner_' + prop.prop_id).toggleClass('hide'); // aparecer loading
                $('.ganhou_' + prop.prop_id).toggleClass('hide'); // ocultar icone ganhou
                $('.perdeu_' + prop.prop_id).toggleClass('hide'); // ocultar icone perdeu
            });

            function DialogModalMotivoController($scope, $mdDialog, $timeout, $http, dadosProp, motivoPropostaService, loadSaas) {

                vm = this;
                vm.dadosProp = dadosProp;
                vm.trueProgress = false;
                vm.motivoRepeat = null;
                vm.motModal = {
                    "motivo": "",
                    "observacao": ""
                }

                /** 
                |  GET REGISTRO
                **/
                var list_registro_motivo = function() {
                    vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                    vm.items.then(function(items) { // ler array de retorno
                        vm.saasid = items;
                        var dados = {
                            'saasid': vm.saasid
                        };
                        motivoPropostaService.findAll(dados).then(function(response) {
                            if (response.data != 0) {
                                $timeout(function() {
                                    vm.motivoRepeat = response.data;
                                });
                            } else {
                                $timeout(function() {
                                    vm.motivoRepeat = '';
                                });
                            }
                        }, function(error) {
                            $log.error(error);
                        });
                    });

                }
                list_registro_motivo();

                vm.limpa = function() {
                    vm.motModal = {
                        "motivo": "",
                        "observacao": ""
                    }
                }

                vm.cancelarModalMotivoProp = function() {
                    $mdDialog.cancel();
                    vm.limpa();
                }

                vm.confirmarModalMotivoProp = function(prop, mot) {
                    angular.element("#enviandomotivoPerdaModal").hide()
                    vm.trueProgress = true;

                    FactoryWebhook.triggerSit(prop.prop_id);

                    var dados = {
                        'qual': 2,
                        'id': prop.prop_id,
                        'user_hash': Cookies.get('crm_funil_hash'),
                        'mot': mot
                    };

                    motivoPropostaService.perderNegocicacao(dados).then(function(response) {
                        console.log("perderNegocicacao");
                        console.log(response.data);
                            $timeout(function() {
                                angular.element("#enviandomotivoPerdaModal").show();
                                vm.trueProgress = false;
                                $mdDialog.hide(mot);
                            });
                        })
                        .catch((err) => {
                            console.log(err)
                        });
                }

                vm.growableOptionsMot = {
                    displayText: 'Selecione o motivo...',
                    emptyListText: 'Nenhum motivo cadastrado',
                    onAdd: function(texto) {
                        var deferred = $q.defer();
                        vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                        vm.items.then(function(items) {
                            vm.saasid = items;
                            var dados = {
                                'mot_descricao': texto,
                                'saas': vm.saasid
                            };
                            $('.check_cadastro_origem').hide(); // remove o check
                            $('.spinner_cadastro_origem').toggleClass('hide'); // aparecer loading
                            motivoPropostaService.cadastrarMotivoPropostaRapido(dados).then(function(response) {
                                if (response.data[0].mot_id) {
                                    list_registro_motivo();
                                    $('.spinner_cadastro_origem').toggleClass('hide'); // remover loading
                                    $('.check_cadastro_origem').fadeIn('slow'); // mostra check sucesso
                                    $('.limpar').val(''); // limpar input após cadastro
                                    $timeout(function() {
                                        $('.check_cadastro_origem').fadeOut('slow'); // após 2 segundos remove o check
                                    }, 2000);
                                    var motivo = {
                                        'mot_id': response.data[0].mot_id,
                                        'mot_descricao': response.data[0].mot_descricao,
                                        'fk_saas_id': response.data[0].fk_saas_id
                                    };
                                }
                                deferred.resolve(motivo);
                            }, function(error) {
                                $log.error(error);
                            });
                        });
                        return deferred.promise;
                    }
                };
            }
        }

    }
}());