(function() {
    'use strict';

    angular
        .module('app')
        .component('gerarCobrancaA9', {
            templateUrl: 'app/components/gerarCobrancaA9/gerarCobrancaA9.template.html',
            bindings: {
                neg: '=',
                callbackNeg: '&'
            },
            controller: function($timeout, API, cobrancaService, $window){

                const $ctrl = this;

                console.log("$ctrl.neg");
                console.log($ctrl.neg);
                

                $ctrl.loading = true;
                $ctrl.cobranca = '';
                $ctrl.tipo_cobranca = '';
                $ctrl.cobranca_parcelas = 1;
                $ctrl.url = API.url;
                $ctrl.alterarData = false;
                $ctrl.invalidNeg = false;

                $ctrl.showModalCobranca = function(){
                    $('[data-toggle="tooltip"]').tooltip('hide');
                    $ctrl.buscarCobranca();
                    $ctrl.alterarData = false;

                    var modalCobranca = $("#modalCobrancaA9");
                    modalCobranca.modal({
                        show: true
                    }).on('shown.bs.modal', function(e) {
                        $ctrl.aplicarPicker();
                    });
                    if(modalCobranca.parent().get(0).tagName != 'BODY'){
                        $('.modal-backdrop').insertAfter(modalCobranca);
                    }
                }

                $ctrl.aplicarPicker = function() {
                    $('#campo_data_cobranca_a9').focus();
                    $('#campo_data_cobranca_a9').daterangepicker({
                        singleDatePicker: true,
                        showDropdowns: true,
                        minDate: moment().format('DD/MM/YYYY'),
                        locale: {
                            format: 'DD/MM/YYYY'
                        }
                    });
                    // $('#campo_data_cobranca_a9').data('daterangepicker').setStartDate(vm.negocios.data_ganho_perda);
                    $("#campo_data_cobranca_a9").trigger("click");
                }

                $ctrl.buscarCobranca = function() {
                    $ctrl.loading = true;
                    var dados = {
                        'id': $ctrl.neg.id
                    };
                    cobrancaService.buscaCobranca(dados).then(function (response) {
                        console.log("buscaCobranca");
                        console.log(response.data);
                        if (response.data){
                            $ctrl.cobranca = response.data;
                            if (!response.data.fk_tvc_id || response.data.prop_ganho == "0" || response.data.prop_valor_total < 5){
                                $ctrl.invalidNeg = true;
                            } else {
                                $ctrl.invalidNeg = false;
                            }
                        }
                        $ctrl.loading = false;
                    }, function(error) {
                        console.log(error);
                    });
                }

                $ctrl.editarData = function() {
                    $ctrl.alterarData = !$ctrl.alterarData;
                    $ctrl.data_cobranca = angular.copy(moment($ctrl.cobranca.cobranca_vencimento, 'YYYY-MM-DD').format('DD/MM/YYYY'));
                    $ctrl.cobranca_tipo = angular.copy($ctrl.cobranca.cobranca_tipo);
                    $ctrl.cobranca_parcelas = angular.copy($ctrl.cobranca.cobranca_parcelas == 0 ? '1' : $ctrl.cobranca.cobranca_parcelas);
                    $ctrl.cobranca_descricao = angular.copy($ctrl.cobranca.cobranca_descricao);
                    $timeout(function(){
                        $ctrl.aplicarPicker();
                    }, 200);
                }

                $ctrl.alterarDataCobranca = function() {
                    $ctrl.salvandoCobranca = true;
                    if ($ctrl.cobranca_tipo != 'cc'){
                        $ctrl.cobranca_parcelas = 0;
                    }
                    var dados = {
                        'data': $ctrl.data_cobranca,
                        'cobranca_tipo': $ctrl.cobranca_tipo,
                        'cobranca_parcelas': $ctrl.cobranca_parcelas,
                        'cobranca_descricao': $ctrl.cobranca_descricao,
                        'id': $ctrl.neg.id,
                        'user_hash': Cookies.get('crm_funil_hash')
                    };
                    cobrancaService.alterarDataCobranca(dados).then(function (response) {
                        console.log("alterarData");
                        console.log(response.data);
                        if (response.data.data){
                            swal({
                                title: 'Alterada com sucesso!',
                                type: 'success',
                                text: 'Data alterada com sucesso!',
                                showConfirmButton: false,
                                timer: 1000
                            });
                            $ctrl.salvandoCobranca = false;
                            $ctrl.alterarData = false;
                            $ctrl.buscarCobranca();
                        }
                    }, function(error) {
                        console.log(error);
                    });
                }

                $ctrl.salvarCobranca = function() {
                    $ctrl.salvandoCobranca = true;
                    if ($ctrl.cobranca_tipo != 'cc'){
                        $ctrl.cobranca_parcelas = 0;
                    }
                    var dados = {
                        'data': $ctrl.data_cobranca,
                        'cobranca_tipo': $ctrl.cobranca_tipo,
                        'cobranca_parcelas': $ctrl.cobranca_parcelas,
                        'cobranca_descricao': $ctrl.cobranca_descricao,
                        'id': $ctrl.neg.id,
                        'user_hash': Cookies.get('crm_funil_hash')
                    };
                    console.log("dados");
                    console.log(dados);
                    cobrancaService.gerarLinkCobranca(dados).then(function (response) {
                        console.log("salvarCobranca");
                        console.log(response.data);
                        // $('#modalCobrancaA9').modal('hide');
                        if (response.data.hash){
                            swal({
                                title: 'Gerada com sucesso!',
                                type: 'success',
                                text: 'Cobrança gerada com sucesso!',
                                showConfirmButton: false,
                                timer: 1000
                            });
                            $ctrl.salvandoCobranca = false;
                            // $ctrl.hashCobranca = response.data.hash;
                            $ctrl.buscarCobranca();
                        }
                    }, function(error) {
                        console.log(error);
                    });
                }

                $ctrl.removerCobranca = function() {
                    swal({
                        title: 'Deseja realmente remover esta cobrança?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        closeOnConfirm: false
                      },
                      function() {
                        $window.onkeydown = null;
                        $window.onfocus = null;
                        swal.close();
                        $ctrl.removendoCobranca = true;
                        var dados = {
                            'id': $ctrl.neg.id,
                            'user_hash': Cookies.get('crm_funil_hash')
                        };
                        cobrancaService.removerCobranca(dados).then(function (response) {
                            console.log("removerCobranca");
                            console.log(response.data);
                            // $ctrl.removendoCobranca = false;
                            // return null;
                            if (!response.data.errors){
                                $('#modalCobrancaA9').modal('hide');
                                console.log("aaaaaaaaaaaaaaaaaaaa");
                                swal({
                                    title: 'Removida com sucesso!',
                                    type: 'success',
                                    text: 'Cobrança removida com sucesso!',
                                    showConfirmButton: false,
                                    timer: 1000
                                });
                                $ctrl.buscarCobranca();
                            } else {
                                swal({
                                    title: 'Erro ao remover cobrança!',
                                    type: 'error',
                                    text: response.data.errors[0].description,
                                    showConfirmButton: true,
                                });
                            }
                            $ctrl.removendoCobranca = false;
                        }, function(error) {
                            console.log(error);
                        });
                    });
                }

            }
        })
}());
