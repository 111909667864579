(function() {
    'use strict';

    angular
        .module('negocios')
        .controller('negociosController', negociosController);

    negociosController.$inject = ['$routeParams', 'mensagem', '$location', 'negociosService', 'PessoasService', 'empresasService', '$http', 'API', '$filter', '$timeout', '$q', 'loadSaas', 'validarSaas', 'verificaLeads', 'rangeDatas', 'produtosService', 'FactoryRoles', '$window', '$log', 'APINode', 'RC', 'FactoryWebhook', 'FactoryNotificacao', '$scope', 'origemContatoService', 'FactoryModalMotivo', '$rootScope', 'loadFunisVenda', 'turmasVCService'];

    function negociosController($routeParams, mensagem, $location, negociosService, PessoasService, empresasService, $http, API, $filter, $timeout, $q, loadSaas, validarSaas, verificaLeads, rangeDatas, produtosService, FactoryRoles, $window, $log, APINode, RC, FactoryWebhook, FactoryNotificacao, $scope, origemContatoService, FactoryModalMotivo, $rootScope, loadFunisVenda, turmasVCService) {

        var vm = this;

        vm.url = API.url;

        vm.searchUrl = function(qual, value) {
            $location.search(qual, value);
        }

        vm.resultCriaRole = FactoryRoles.checkRolesCria('isModPessoas');
        vm.resultCriaRoleNeg = FactoryRoles.checkRolesCria('isModNegociacao');
        vm.resultVisuFunil = FactoryRoles.checkRolesVisuFUNIL('isModFunil');

        var varRolesStorageNe = FactoryRoles.checkRolesStorage('isModNegociacao');
        var varRolesStorageFun = FactoryRoles.checkRolesStorage('isModFunil');

        var truVisuGroupCargoSelec = FactoryRoles.visuGroupCargoSelec('isModConfig');
        vm.veriVisuSelectCargCSS = function(dados) {
            if (dados == false) {
                var desabilitarSelect = 'disable_a2';
                return desabilitarSelect;
            }
        };
        vm.desabilitarSelect = vm.veriVisuSelectCargCSS(truVisuGroupCargoSelec);
        // verificar se for falso, retornar um true para colocar na class

        moment.updateLocale('pt-br');

        vm.responsavel = '';
        vm.url_img = API.url;

        vm.tirarBTN = function() {
            $('span[role=button]').addClass('background_style_btn');
        };

        $timeout(function() {
            $('.ui-select-choices-content').niceScroll();
        });

        vm.setarIMG = function(id) {
            $timeout(function() {
                vm.rand = (new Date()).toString();
            });
        };

        $timeout(function() {
            var slug = '';
            var tipo = '';
            //validação para validar o botão voltar, e indicar para qual tela o mesmo deve direcionar quando for acionado
            if (Cookies.get('pessoa_slug')) {
                slug = Cookies.get('pessoa_slug');
                tipo = 'p';
                vm.cancelar = '/pessoas/negocio/' + slug;
            } else if (Cookies.get('empresa_slug')) {
                slug = Cookies.get('empresa_slug');
                tipo = 'e';
                vm.cancelar = '/empresas/negocio/' + slug;
            } else if (Cookies.get('pessoa_slug_update')) {
                slug = Cookies.get('pessoa_slug_update');
                tipo = 'p';
                vm.cancelar = '/pessoas/negocio/' + slug;
            } else if (Cookies.get('empresa_slug_update')) {
                slug = Cookies.get('empresa_slug_update');
                tipo = 'e';
                vm.cancelar = '/empresas/negocio/' + slug;
            } else {
                tipo = '';
                vm.cancelar = '/negocios';
            }
            if (slug != '' && $location.url().match(/cadastrar/)) {
                //se houver um slug enviado através dos cookies acima e estiver na tela de cadastro de negociação cai aqui
                var dados = {
                    'slug': slug,
                    'tipo': tipo
                };
                negociosService.carregarNegocioPE(dados).then(function(response) {
                    //carrega o nome da cliente para aparecer marcado automaticamente quando o cadastro for feito através da tela de pessoas ou empresa
                    $timeout(function() {
                        if (response.data != '0') {
                            //se encontrar um cliente cai aqui
                            if (response.data[0].id) {
                                //se o cliente for pessoa cai aqui e aplica no array
                                $('#pessoa_empresa_selecionado').attr('value', 'p');
                                var cliente_empresa = {
                                    'id': response.data[0].cli_id,
                                    'nome': response.data[0].cli_nome,
                                    'tipo': 'Pessoas',
                                };
                            } else {
                                //se o cliente for empresa cai aqui e aplica no array
                                $('#pessoa_empresa_selecionado').attr('value', 'e');
                                var cliente_empresa = {
                                    'id': response.data[0].emp_id,
                                    'nome': response.data[0].emp_nome,
                                    'tipo': 'Empresas',
                                };
                            }
                            vm.negocios.cliente_empresa = cliente_empresa;
                        }
                        //se não houver retorno da consulta não faz nada apenas não exibe o cliente - isso ocorre quando o cadastro é realizado pela tela geral de negociações
                    });
                }, function(error) {
                    $log.error(error);
                });
            }
        });

        vm.deletarDados = function() {
            $timeout(function() {
                //apagar cookies criados anteriormente
                Cookies.remove('pessoa_slug');
                Cookies.remove('empresa_slug');
                Cookies.remove('pessoa_slug_update');
                Cookies.remove('empresa_slug_update');
            });
        };

        // $timeout(function () {
        //     if ($location.url().substring(0, 17) == '/negocios/editar/' || $location.url().substring(0, 19) == '/negocios/cadastrar') {
        //         vm.deletarDados();
        //     }
        // });

        vm.slugClienteN = $routeParams.slugCliente;
        vm.box_filtro_home = false;
        vm.adm = false;
        vm.verificarAdm = function() {
            negociosService.verificarAdm(Cookies.get('crm_funil_hash')).then(function(response) {
                if (response.data == 'Administrador') {
                    vm.adm = true;
                } else {
                    vm.adm = false;
                }
            }, function(error) {
                $log.error('Erro: ', error);
            });
        };
        vm.verificarAdm();

        vm.LimpaBusca = function() {
            delete vm.busca;
            vm.buscando = false;
            vm.searchUrl('busca', null);
            vm.ordenarNegociacoes();
        };

        vm.a_z = true;
        vm.order_az = function() {
            if (vm.a_z == true) { // coloca em order decrescente
                vm.a_z = false;
                vm.z_a = true;
                vm.direcao = !vm.direcao;
            } else { // coloca em order crescente
                vm.direcao = !vm.direcao;
                vm.a_z = true;
                vm.z_a = false;
            }
        };

        vm.categ = {
            'id': '',
            'nome': ''
        };

        vm.limpar = function() {
            vm.categ = {
                'id': '',
                'nome': ''
            };
        };

        vm.reativarCliente = function() {
            if ($location.url().match(/empresas/)) { // EMPRESAS
                swal({
                        title: 'Deseja realmente reativar esta empresa?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        closeOnConfirm: false
                    },
                    function() {
                        $window.onkeydown = null;
                        $window.onfocus = null;

                        empresasService.findUnique($routeParams.slugCliente).then(function(response) {
                            if (response.data[0].emp_email != '' && response.data[0].emp_email != null) { // E-MAIL ESTÁ PREENCHIDO
                                var dados = {
                                    'slug': $routeParams.slugCliente,
                                    'saasid': vm.saasid,
                                    'email': response.data[0].emp_email
                                };
                                empresasService.validaExisteEmailEdit(dados).then(function(response) {
                                    if (response.data != '') {
                                        if (response.data[0].tipo == 'p') {
                                            swal({
                                                title: 'Operação não realizada!',
                                                text: 'Já existe uma pessoa ativa com o e-mail informado.<br><span style=\'font-size:12px;font-weight: 300;\'>Clique no link abaixo para acessá-la.</span><br><br><a href=\'/pessoas/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                                    response.data[0].nome + '</a>',
                                                type: 'error',
                                                showConfirmButton: true,
                                                html: true
                                            }, function() {
                                                $timeout(function() {
                                                    $('#email').focus();
                                                });
                                            });
                                        } else if (response.data[0].tipo == 'e') {
                                            swal({
                                                title: 'OboxDeperação não realizada!',
                                                text: 'Já existe uma empresa ativa com o e-mail informado.<br><span style=\'font-size:12px;font-weight: 300;\'>Clique no link abaixo para acessá-la.</span><br><br><a href=\'/empresas/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                                    response.data[0].nome + '</a>',
                                                type: 'error',
                                                showConfirmButton: true,
                                                html: true
                                            }, function() {
                                                $timeout(function() {
                                                    $('#email').focus();
                                                });
                                            });
                                        }
                                    } else {
                                        vm.reativarE();
                                    }
                                }, function(error) {
                                    $log.error(error);
                                });
                            } else { // NÃO TEM E-MAIL PREENCHIDO
                                vm.reativarE();
                            }
                        }, function(error) {
                            $log.error(error);
                        });
                    });
            } else { // PESSOAS
                swal({
                        title: 'Deseja realmente reativar esta pessoa?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        closeOnConfirm: false
                    },
                    function() {
                        $window.onkeydown = null;
                        $window.onfocus = null;
                        PessoasService.findUnique($routeParams.slugCliente).then(function(response) {
                            if (response.data[0].cli_email != '' && response.data[0].cli_email != null) { // E-MAIL ESTÁ PREENCHIDO
                                var dados = {
                                    'slug': $routeParams.slugCliente,
                                    'saasid': vm.saasid,
                                    'email': response.data[0].cli_email
                                };
                                PessoasService.validaExisteEmailEdit(dados).then(function(response) {
                                    if (response.data != '') {
                                        if (response.data[0].tipo == 'p') {
                                            swal({
                                                title: 'Operação não realizada!',
                                                text: 'Já existe uma pessoa ativa com o e-mail informado.<br><span style=\'font-size:12px;font-weight: 300;\'>Clique no link abaixo para acessá-la.</span><br><br><a href=\'/pessoas/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                                    response.data[0].nome + '</a>',
                                                type: 'error',
                                                showConfirmButton: true,
                                                html: true
                                            }, function() {
                                                $timeout(function() {
                                                    $('#email').focus();
                                                });
                                            });
                                        } else if (response.data[0].tipo == 'e') {
                                            swal({
                                                title: 'Operação não realizada!',
                                                text: 'Já existe uma empresa ativa com o e-mail informado.<br><span style=\'font-size:12px;font-weight: 300;\'>Clique no link abaixo para acessá-la.</span><br><br><a href=\'/empresas/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                                    response.data[0].nome + '</a>',
                                                type: 'error',
                                                showConfirmButton: true,
                                                html: true
                                            }, function() {
                                                $timeout(function() {
                                                    $('#email').focus();
                                                });
                                            });
                                        }
                                    } else {
                                        vm.reativarP();
                                    }
                                }, function(error) {
                                    $log.error(error);
                                });
                            } else { // NÃO TEM E-MAIL PREENCHIDO
                                vm.reativarP();
                            }
                        }, function(error) {
                            $log.error(error);
                        });
                    });
            }
        };

        vm.reativarP = function() {
            // LIMITAÇÃO DE LEADS --- INÍCIO
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                vm.leads = verificaLeads(vm.saasid); // carregar saas id
                vm.leads.then(function(info) {
                    if (info.limite <= info.quantia) {
                        swal.close();
                        $('#modalAlertaLeads').modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                        vm.salvando = false;
                    } else {
                        var dados = {
                            'slug': $routeParams.slugCliente
                        };
                        PessoasService.reativaCliente(dados).then(function(response) {
                            swal({
                                title: 'Reativado!',
                                text: 'Pessoa reativada com sucesso!',
                                type: 'success',
                                showConfirmButton: false,
                                timer: 2000
                            });
                            $timeout(function() {
                                vm.carregarTítulo();
                            }, 2000);
                        }, function(error) {
                            $log.error(error);
                        });
                    }
                });
            });
            // LIMITAÇÃO DE LEADS --- FIM
        };

        vm.reativarE = function() {
            // LIMITAÇÃO DE LEADS --- INÍCIO
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                vm.leads = verificaLeads(vm.saasid); // carregar saas id
                vm.leads.then(function(info) {
                    if (info.limite <= info.quantia) {
                        swal.close();
                        $('#modalAlertaLeads').modal({
                            backdrop: 'static',
                            keyboard: false
                        });
                        vm.salvando = false;
                    } else {
                        var dados = {
                            'slug': $routeParams.slugCliente
                        };
                        empresasService.reativaEmpresa(dados).then(function(response) {
                            swal({
                                title: 'Reativado!',
                                text: 'Empresa reativada com sucesso!',
                                type: 'success',
                                showConfirmButton: false,
                                timer: 2000
                            });
                            $timeout(function() {
                                vm.carregarTítulo();
                            }, 2000);
                        }, function(error) {
                            $log.error(error);
                        });
                    }
                });
            });
            // LIMITAÇÃO DE LEADS --- FIM
        };


        vm.buscaNegocios = function() {

        };
        vm.buscaNegocios();

        vm.buscaEtapas = function(funil) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'funil_venda': vm.negocios.funv_id
                };
                negociosService.buscaEtapas(dados).then(function(response) {
                    if (response.data != 0) {
                        $timeout(function() {
                            vm.etapasLista = response.data;
                            if (funil){
                                $timeout(function() {
                                    vm.marcarEtapaCadastro(1, vm.etapasLista[0].etapa_id);
                                    vm.etapaEscolhida = vm.etapasLista[0].etapa_nome;
                                });
                            }
                        });
                    } else {
                        $timeout(function() {
                            vm.etapasLista = '';
                        });
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        /*if ($routeParams.slugCliente) {
            vm.buscaEtapas();
        } else {
            //vm.buscaEtapas();
        }*/
        // ALTERADO POIS AS ETAPAS NÃO ERRAM CARREGADAS QUANDO NÃO EXISTIAM NEGOCIAÇÕES CADASTRADAS
        vm.buscaEtapas();

        vm.integrarCompufacil = function(id) {
            var dados = {
                'saasid': vm.saasid,
                'id_prop': id,
                'user_hash': Cookies.get('crm_funil_hash')
            }
            negociosService.integrarCompufacil(dados).then(function(response) {
                // console.log("integrarCompufacil");
                // console.log(response.data);
            }, function(error) {
                $log.error(error);
            });
        }

        vm.situacaoCompufacil = function(id) {
            var dados = {
                'saasid': vm.saasid,
                'id_prop': id,
                'user_hash': Cookies.get('crm_funil_hash')
            }
            negociosService.situacaoCompufacil(dados).then(function(response) {}, function(error) {
                $log.error(error);
            });
        }

        vm.descartarLeadExactSales = function(id) {
            var dados = {
                'saasid': vm.saasid,
                'id_prop': id
            }
            negociosService.descartarLeadExactSales(dados).then(function(response) {
                /*console.log("descartarLeadExactSales");
                console.log(response.data);*/
            }, function(error) {
                $log.error(error);
            });
        }

        vm.venderLeadExactSales = function(id) {
            var dados = {
                'saasid': vm.saasid,
                'id_prop': id
            }
            negociosService.venderLeadExactSales(dados).then(function(response) {
                /*console.log("venderLeadExactSales");
                console.log(response.data);*/
            }, function(error) {
                $log.error(error);
            });
        }

        vm.teste = function(id) {
            console.log("wwwwwwwww");
            console.log(".perdeu_" + id);
            $timeout(function() {
                $(".aaa_" + id).tooltip('destroy');
                $timeout(function() {
                    $(".aaa_" + id).tooltip({
                        title: 'aaaaaaaaaaaaaaa',
                        placement: 'top'
                    });
                }, 200);
            });
        }

        vm.marcar_situacao = function(qual, id, negocio, ev) {

            if (!($('.perdeu_' + id).hasClass('marcado_perdeu')) && qual == 2) { // CLICOU EM PERDEU E A NEGOCIAÇÃO NÃO ESTAVA PERDIDA
                $('.spinner_' + id).toggleClass('hide'); // aparecer loading
                $('.ganhou_' + id).toggleClass('hide'); // ocultar icone ganhou
                $('.perdeu_' + id).toggleClass('hide'); // ocultar icone perdeu
                vm.removerTooltipAcao(id);

                vm.modalMotivoNeg(negocio, ev);
            } else { // CLICOU EM GANHAR OU REABRIR NEGOCIAÇÃO
                FactoryWebhook.triggerSit(id);

                var dados = {
                    'qual': qual,
                    'id': id,
                    'user_hash': Cookies.get('crm_funil_hash')
                };
                $('.spinner_' + id).toggleClass('hide'); // aparecer loading
                $('.ganhou_' + id).toggleClass('hide'); // ocultar icone ganhou
                $('.perdeu_' + id).toggleClass('hide'); // ocultar icone perdeu
                vm.removerTooltipAcao(id);

                negociosService.marcarSituacao(dados).then(function(response) {
                    console.log("marcar_situacao");
                    console.log(response.data);
                    if (qual == 1) {
                        /*$('.ganhou_' + id).toggleClass('marcado_ganhou'); // faz a troca da classe marcado no icone 'ganhou'
                        $('.perdeu_' + id).removeClass('marcado_perdeu'); // remove a classe marcado do icone 'perdeu' caso esteja marcado
                        $('.spinner_' + id).toggleClass('hide');
                        $('.ganhou_' + id).toggleClass('hide');
                        $('.perdeu_' + id).toggleClass('hide');*/

                        vm.situacaoCompufacil(id);
                        vm.venderLeadExactSales(id);
                    }/* else {
                        $('.perdeu_' + id).toggleClass('marcado_perdeu');
                        $('.ganhou_' + id).removeClass('marcado_ganhou');
                        $('.spinner_' + id).toggleClass('hide');
                        $('.ganhou_' + id).toggleClass('hide');
                        $('.perdeu_' + id).toggleClass('hide');
                    }*/
                    /*if ($(".perdeu_" + id).hasClass('tooltipstered')){
                        $(".perdeu_" + id).tooltipster("destroy");
                        $timeout(function() {
                            $(".perdeu_" + id).tooltip({
                                title: '',
                                placement: 'top'
                            });
                        }, 200);
                    }*/

                    /*vm.mostrarTooltipAcao(id);*/
                    $timeout(function() {
                        vm.continuaordenarNegociacoes();
                    });
                    $timeout(function() {
                        vm.removerTooltipAcao(id);
                    }, 4000);
                    //console.log(response)
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            }
        };

        vm.marcar_situacao_cadastro = function(qual) {
            if (qual == 1) { // clicou em ganhou
                if ($('.ganhou_cadastro').hasClass('marcado_ganhou')) { // verifica se jah existe a classe 'marcado ganhou'
                    $('.ganhou_cadastro').removeClass('marcado_ganhou'); // faz a troca da classe marcado no icone 'ganhou'
                    vm.negocios.ganhou = 0;
                } else {
                    $('.ganhou_cadastro').addClass('marcado_ganhou'); // faz a troca da classe marcado no icone 'ganhou'
                    vm.negocios.ganhou = 1;
                    vm.negocios.perdeu = 0;
                }
                $('.perdeu_cadastro').removeClass('marcado_perdeu'); // remove a classe marcado do icone 'perdeu' caso esteja marcado
            } else { // clicou em perdeu
                if ($('.perdeu_cadastro').hasClass('marcado_perdeu')) { // verifica se jah existe a classe 'marcado perdeu'
                    $('.perdeu_cadastro').removeClass('marcado_perdeu'); // remove classe marcado do icone 'perdeu'
                    vm.negocios.perdeu = 0;
                } else {
                    $('.perdeu_cadastro').addClass('marcado_perdeu'); // remove a classe marcado do icone 'perdeu'
                    vm.negocios.perdeu = 1;
                    vm.negocios.ganhou = 0;
                }
                $('.ganhou_cadastro').removeClass('marcado_ganhou');
            }
        };

        vm.mostrarTooltipAcao = function(id) {
            var left = $('.situacao_' + id).offset().left;
            var top = $('.situacao_' + id).offset().top;
            var width = $('.situacao_' + id).width();
            var height = $('.situacao_' + id).height();
            var width_t = $('.acao_' + id).width(); // largura tooltip
            $('.msg_' + id).html('Alterado com sucesso!<br>Clique novamente para desfazer.');
            $('.acao_' + id).css('left', ((width / 2) - (width_t / 2) + 15) + 'px');
            $('.acao_' + id).css('top', (height + 15) + 'px');
            $('.acao_' + id).fadeIn('fast');
        };

        vm.removerTooltipAcao = function(id) {
            $('.acao_' + id).fadeOut('fast');
        };

        vm.destacarEtapasOver = function(posicao, prop_id) {
            var posicao_atual = $('.grafico_interna_' + prop_id).attr('value');
            var destacar = posicao - posicao_atual;
            for (var i = 0; i < destacar; i++) {
                $('.etapa_' + posicao + '_' + prop_id).addClass('etapa_marcada_over');
                posicao--;
            }
        };

        vm.destacarEtapasOverCadastro = function(posicao, prop_id) {
            var posicao_atual = $('.grafico_interna').attr('value');
            var destacar = posicao - posicao_atual;
            for (var i = 0; i < destacar; i++) {
                $('.etapa_' + posicao).addClass('etapa_marcada_over');
                posicao--;
            }
        };

        vm.removerDestaqueEtapas = function() {
            $('.etapas').removeClass('etapa_marcada_over');
        };

        vm.removerDestaqueEtapasCadastro = function() {
            $('.etapas').removeClass('etapa_marcada_over');
        };

        vm.marcarEtapa = function(prop_id, etapa_marcada, etapa_id) {
            FactoryWebhook.triggerUpdate(prop_id);

            var dados = {
                'prop_id': prop_id,
                'etapa_id': etapa_id,
                'user_hash': Cookies.get('crm_funil_hash')
            };
            $('.check_etapa_' + prop_id).hide(); // remove o check
            $('.spinner_etapa_' + prop_id).toggleClass('hide'); // aparecer loading
            negociosService.marcarEtapa(dados).then(function(response) {
                $('.grafico_' + prop_id).removeClass('etapa_marcada');
                var marcada = etapa_marcada;
                for (var i = 0; i <= etapa_marcada; i++) {
                    $('.etapa_' + marcada + '_' + prop_id).addClass('etapa_marcada');
                    marcada--;
                }
                $('.grafico_interna_' + prop_id).attr('value', etapa_marcada);
                $('.spinner_etapa_' + prop_id).toggleClass('hide'); // remover loading
                $('.check_etapa_' + prop_id).fadeIn('slow'); // mostra check sucesso
                $timeout(function() {
                    $('.check_etapa_' + prop_id).fadeOut('slow'); // após 2 segundos remove o check
                }, 2000);
            }, function(error) {
                $log.error(error);
            });
        };

        vm.marcarEtapaCadastro = function(etapa_marcada, etapa_id) {
            $('.grafico_cadastro').removeClass('etapa_marcada');
            var marcada = etapa_marcada;
            for (var i = 0; i <= etapa_marcada; i++) {
                $('.etapa_' + marcada).addClass('etapa_marcada');
                marcada--;
            }
            $('.grafico_interna_cadastro').attr('value', etapa_marcada);
            vm.negocios.etapa = etapa_marcada;
        };

        vm.titulo_negocios = 'Negócios';

        vm.cadastrar = false;
        vm.cadastrarNegocio = function(qual) {
            vm.cadastrar = true;
            $('.box_listagem_negocios').fadeOut(200);
            $timeout(function() {
                $('.box_cadastro_negocios').fadeIn(200);
                if (qual == 'novo') {
                    vm.titulo_negocios = 'Novo Negócio';
                    if (vm.mobile) {
                        $timeout(function() {
                            $('.box_PE').css('width', screen.width - 60 + 'px');
                        });
                    }
                    $timeout(function() {
                        if (vm.etapasLista) {
                            for (var i = 0; i < vm.etapasLista.length; i++) {
                                if (vm.etapasLista[i].etapa_posicao == 1) {
                                    vm.etapaEscolhida = vm.etapasLista[i].etapa_nome; // marca a primeira etapa durante o cadastro
                                }
                            }
                        }
                    });
                } else {
                    vm.titulo_negocios = 'Editar Negócio';
                }

                $('#campo_nome').focus();
            }, 200);
        };
        //vm.cadastrarNegocio();

        vm.cancelarCadastrarNegocio = function() {
            $('.box_cadastro_negocios').fadeOut(200);
            vm.limpar();
            $timeout(function() {
                $('.box_listagem_negocios').fadeIn(200);
                vm.titulo_negocios = 'Negócios';
            }, 200);
            vm.editando = false;
            vm.cadastrar = false;
        };

        vm.negocios = {
            'slug': '',
            'nome': '',
            'cliente_empresa': '',
            'qual_selecionado': '',
            'valor': '',
            'prazo': '',
            'etapa': '1',
            'ganhou': '0',
            'perdeu': '0',
            'observacao': '',
            'responsavel': ''
        };

        vm.validar = {
            'nome': false,
            'cliente_empresa': false,
            'valor': false,
            'prazo': false,
            'responsavel': false,
        };

        vm.limpar = function() {
            vm.negocios = {
                'nome': '',
                'cliente_empresa': '',
                'qual_selecionado': '',
                'valor': '',
                'prazo': '',
                'etapa': '1',
                'ganhou': '0',
                'perdeu': '0',
                'observacao': ''
            };
        };

        vm.usuarios = [];
        vm.buscaUsuarios = function(responsavel, acao) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash'),
                    'acao': acao,
                    'mod_id': 2
                };
                negociosService.findUsuarios(dados).then(function(response) {
                    $timeout(function() {
                        vm.usuarios = response.data;
                        $timeout(function() {
                            if (responsavel) {
                                for (var x = 0; x < vm.usuarios.length; x++) {
                                    if (vm.usuarios[x].user_id == responsavel) {
                                        vm.responsavel = vm.usuarios[x];
                                    }
                                }
                            }
                        });
                        //if ($routeParams.cadastrar) {
                        var dados = {
                            'hash': Cookies.get('crm_funil_hash')
                        }
                        negociosService.findUserLogado(dados).then(function(response) {
                            $timeout(function() {
                                // FILTRAR USUARIOS ATIVOS
                                if ($routeParams.cadastrar) {
                                    vm.usuarios = $filter('filter')(vm.usuarios, {
                                        user_ativo: '1'
                                    });
                                }
                                vm.negocios.responsavel = response.data[0].user_id;
                                for (var x = 0; x < vm.usuarios.length; x++) {
                                    if (vm.usuarios[x].user_id == response.data[0].user_id) {
                                        vm.responsavel = vm.usuarios[x];
                                    }
                                }
                                vm.verifMostrarBtnEditar();
                            });
                        }, function(error) {
                            $log.error(error);
                        });
                        //}
                        //vm.filtrar_responsavel();
                    });
                }, function(error) {
                    $log.error(error);
                });
            }, function(error) {
                $log.error(error);
            });
        };
        //vm.buscaUsuarios();

        if ($routeParams.cadastrar) {
            vm.ocultarFiltros = true;
            vm.cadastrarNegocio('novo');
            vm.buscaUsuarios('', 'CADASTRA');
        } else {
            vm.ocultarFiltros = false;
            vm.buscaUsuarios('', 'VISUALIZA');
        }

        vm.fim_repeat = function() {
            $('#spinner_carregar_pessoas_empresas').hide();
        };

        vm.acabouRepeatLista = function(id, etapas) {
            $timeout(function() {
                var qtd_etapas = etapas.length;
                if (!vm.mobile) {
                    var largura_barra_etapas = $('.grafico_interna_' + id).width();
                } else {
                    var largura_barra_etapas = $('.grafico_interna_' + id).width() - 0.5;
                }
                var tamanho_etapas = largura_barra_etapas / qtd_etapas;
                $('.etapas_lista_' + id).css('width', tamanho_etapas + 'px');
                //$(".etapas_lista_" + id).fadeIn("slow");
                $('.etapas_lista_' + id).show();
                $('[data-toggle="tooltip"]').tooltip();
                /*});*/
            }, 200);
        };

        vm.growableOptions = {
            async: true,
            displayText: 'Pessoas/Empresas',
            emptyListText: 'Procurando registros...',
            //emptyListText: '"'+msg+'"',
            onAdd: function(texto, qual) {
                var deferred = $q.defer();
                // LIMITAÇÃO DE LEADS --- INÍCIO
                vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                vm.items.then(function(items) {
                    vm.saasid = items;
                    vm.leads = verificaLeads(vm.saasid); // carregar saas id
                    vm.leads.then(function(info) {
                        if (info.limite <= info.quantia) {
                            $('#modalAlertaLeads').modal({
                                backdrop: 'static',
                                keyboard: false
                            });
                            vm.salvando = false;
                        } else { // PROSEGUIR CADASTRO ----------------------------
                            var dados = {
                                'nome': texto,
                                'saasid': vm.saasid
                            };
                            $('.check_cadastro').hide(); // remove o check
                            $('.spinner_cadastro').toggleClass('hide'); // aparecer loading
                            vm.dadosCadastro = '';
                            if (qual == 'p') { // clicou no "cadastrar pessoa"
                                vm.abrirModalCadastroRapido(dados, 'p');
                                $('#modalCadastroRapido').on('hidden.bs.modal', function(e) {
                                    $('#modalCadastroRapido').off();
                                    if (vm.dadosCadRapido.salvar && vm.dadosCadRapido.qual == 'p') {
                                        negociosService.cadastrarPessoaRapido(vm.dadosCadRapido).then(function(response) {
                                            //vm.listarPessoasEmpresas(response.data);
                                            vm.dadosCadastro = response.data;
                                            FactoryWebhook.triggerPessoa(response.data.id);

                                            deferred.resolve(response.data);
                                        }, function(error) {
                                            $log.error(error);
                                        });
                                    } else {
                                        $timeout(function() {
                                            $('.spinner_cadastro').toggleClass('hide'); // remover loading
                                        });
                                    }
                                });
                            } else if (qual == 'e') { // clicou no "cadastrar empresa"
                                vm.abrirModalCadastroRapido(dados, 'e');
                                $('#modalCadastroRapido').on('hidden.bs.modal', function(e) {
                                    $('#modalCadastroRapido').off();
                                    if (vm.dadosCadRapido.salvar && vm.dadosCadRapido.qual == 'e') {
                                        negociosService.cadastrarEmpresaRapido(vm.dadosCadRapido).then(function(response) {
                                            //vm.listarPessoasEmpresas(response.data);
                                            vm.dadosCadastro = response.data;
                                            FactoryWebhook.triggerEmpresa(response.data.id);

                                            deferred.resolve(response.data);
                                        }, function(error) {
                                            $log.error(error);
                                        });
                                    } else {
                                        $timeout(function() {
                                            $('.spinner_cadastro').toggleClass('hide'); // remover loading
                                        });
                                    }
                                });
                            }
                        }
                    });
                });
                // LIMITAÇÃO DE LEADS --- FIM

                return deferred.promise;
            },
            onSelect: function(qual) {
                if (qual.slug) {
                    vm.slugPE = qual.slug;
                }
            }
        };

        vm.asyncPE = function(term) {
            var deferred = $q.defer();
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'term': term,
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash')
                }
                $timeout(function() {
                    var altura_box = angular.element(".box_menu_lista_registros").height();
                    angular.element(".box_loading_registros").css("height", altura_box + "px");
                    angular.element(".box_loading_registros").css("padding-top", altura_box / 2 + "px");
                    angular.element(".box_lista_registros").hide();
                    angular.element(".box_loading_registros").show();
                });
                negociosService.listarPessoasEmpresas(dados).then(function(response) {
                    /*console.log("listarPessoasEmpresas");
                    console.log(response.data);*/
                    if (response.data != '0') {
                        vm.listaPessoasEmpresas = response.data;
                        if (vm.dadosCadastro) {
                            vm.negocios.cliente_empresa = vm.dadosCadastro;
                            $('.spinner_cadastro').toggleClass('hide'); // remover loading
                            $('.spinner_cadastro').addClass('hide'); // remover loading
                            $('.check_cadastro').fadeIn('slow'); // mostra check sucesso
                            $('.limpar').val(''); // limpar input após cadastro
                            $timeout(function() {
                                $('.check_cadastro').fadeOut('slow'); // após 2 segundos remove o check
                            }, 2000);
                            vm.slugPE = vm.dadosCadastro.slug;
                        }
                    } else {
                        vm.listaPessoasEmpresas = [];
                    }

                    if (!response.data.length) { // informar mensagem a ser exibida no custom select
                        $('.spinner_pessoa_empresa').hide();
                        $('.listaLimpaTexto').html('Nenhum registro encontrado');
                    } else {
                        $('.spinner_pessoa_empresa').show();
                        $('.listaLimpaTexto').html('Procurando registros...');
                    }
                    deferred.resolve(response.data);

                }, function(error) {
                    $log.error(error);
                });

            }, function(error) {
                $log.error(error);
            });

            deferred.promise.then(function(dados, b) {
                $timeout(function() {
                    angular.element(".box_lista_registros").show();
                    angular.element(".box_loading_registros").hide();
                })
            });

            return deferred.promise;
        }

        /*vm.listaPessoasEmpresas = [];
        vm.listarPessoasEmpresas = function(dadosCadastro) {
            // variável 'dadosCadastro' utilizada para informar que a listagem de pessoas e empresas foi chamada logo após o cadastro rápido no elemento
            vm.items = loadSaas(Cookies.get('crm_funil_hash'));
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash')
                }
                vm.listaPessoasEmpresas = [];
                negociosService.listarPessoasEmpresas(dados).then(function(response) {
                    if (response.data != '0') {
                        vm.listaPessoasEmpresas = response.data;
                        if (dadosCadastro) {
                            vm.negocios.cliente_empresa = dadosCadastro;
                            $('.spinner_cadastro').toggleClass('hide'); // remover loading
                            $('.spinner_cadastro').addClass('hide'); // remover loading
                            $('.check_cadastro').fadeIn('slow'); // mostra check sucesso
                            $('.limpar').val(''); // limpar input após cadastro
                            $timeout(function() {
                                $('.check_cadastro').fadeOut('slow'); // após 2 segundos remove o check
                            }, 2000);
                            vm.slugPE = dadosCadastro.slug;
                        }
                    } else {
                        vm.listaPessoasEmpresas = [];
                    }

                    if (vm.listaPessoasEmpresas == '') { // informar mensagem a ser exibida no custom select
                        $('.spinner_pessoa_empresa').hide();
                        $('.listaLimpaTexto').html('Nenhum registro encontrado');
                    } else {
                        $('.spinner_pessoa_empresa').show();
                        $('.listaLimpaTexto').html('Procurando registros...');
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };
        vm.listarPessoasEmpresas();*/

        /*CONFIGURAÇÕES MODAL CADASTRO RAPIDO --- INICIO*/


        vm.empresasRepeat = [];
        vm.listarEmpresas = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                vm.empresas = [];
                PessoasService.listarEmpresas(dados).then(function(response) {
                    if (response.data != '0') {
                        vm.empresas = response.data;
                    } else {
                        vm.empresas = [];
                    }
                    vm.empresasRepeat.push({
                        'icon': '<i class=\'fa fa-times\'></i>',
                        'id': '',
                        'name': 'Nenhuma empresa',
                        'ticked': ''
                    });
                    for (var x = 0; x < vm.empresas.length; x++) {
                        vm.empresasRepeat.push({
                            'id': vm.empresas[x].emp_id,
                            'name': vm.empresas[x].emp_nome,
                            'ticked': false
                        });
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        vm.listarCargos = function() {
            vm.cargosRepeat = [];
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                PessoasService.listarCargos(dados).then(function(response) {
                    vm.cargosRepeat.push({
                        'icon': '<i class=\'fa fa-times\'></i>',
                        'name': 'Nenhum cargo',
                        'ticked': false,
                        'id': 'nenhum'
                    });
                    if (response.data != '0') {
                        angular.forEach(response.data, function(value, key) {
                            vm.cargosRepeat.push({
                                'name': value.car_nome,
                                'ticked': false,
                                'id': value.car_id
                            });
                        });
                    } else {
                        vm.cargosRepeat = [];
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        /*ORIGEM DO CONTATO --- INICIO*/

        vm.growableOptions5 = {
            displayText: 'Selecione a origem',
            emptyListText: 'Nenhuma origem cadastrada',
            onAdd: function(texto) {
                var deferred = $q.defer();
                // LIMITAÇÃO DE LEADS --- INÍCIO
                vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                vm.items.then(function(items) {
                    vm.saasid = items;
                    var dados = {
                        'nome': texto,
                        'saasid': vm.saasid
                    };
                    $('.check_cadastro_origem').hide(); // remove o check
                    $('.spinner_cadastro_origem').toggleClass('hide'); // aparecer loading
                    origemContatoService.cadastrarOrigemContatoRapido(dados).then(function(response) {
                        if (response.data[0].orc_id) {
                            vm.listarOrigem();
                            $('.spinner_cadastro_origem').toggleClass('hide'); // remover loading
                            $('.check_cadastro_origem').fadeIn('slow'); // mostra check sucesso
                            $('.limpar').val(''); // limpar input após cadastro
                            $timeout(function() {
                                $('.check_cadastro_origem').fadeOut('slow'); // após 2 segundos remove o check
                            }, 2000);
                            var origem = {
                                'orc_id': response.data[0].orc_id,
                                'orc_nome': response.data[0].orc_nome
                            };
                        }
                        deferred.resolve(origem);
                    }, function(error) {
                        $log.error(error);
                    });
                });
                return deferred.promise;
            }
        };

        vm.origemRepeat = [];
        vm.listarOrigem = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                vm.empresas = [];
                origemContatoService.findAll(dados).then(function(response) {
                    if (response.data != '0') {
                        vm.origemRepeat = response.data;
                    } else {
                        vm.origemRepeat = [];
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        vm.limparOrigemCadRapido = function() {
            vm.dadosCadRapido.origem = {
                'orc_id': '',
                'orc_nome': ''
            };
            if (vm.executando == false) {
                $('#msgOrigemCadRapido').fadeIn(200);
                vm.executando = true;
                $timeout(function() {
                    $('#msgOrigemCadRapido').fadeOut(200);
                    vm.executando = false;
                }, 2000);
            }
        };

        /*ORIGEM DO CONTATO --- FIM*/

        vm.abrirModalCadastroRapido = function(dados, qual) {
            vm.dadosCadRapido = dados;
            vm.dadosCadRapido.salvar = false;
            vm.dadosCadRapido.qual = qual;
            vm.dadosCadRapido.categoria = '0';
            vm.dadosCadRapido.email = '';
            vm.dadosCadRapido.telefone = 55;
            vm.dadosCadRapido.telefone2 = 55;
            vm.dadosCadRapido.cargo = '';
            vm.dadosCadRapido.origem = '';
            vm.dadosCadRapido.user_hash = Cookies.get('crm_funil_hash');
            vm.validarCadRapido = {
                'nome': false
            };
            vm.listarEmpresas();
            vm.listarCargos();
            vm.listarOrigem();
            $('#modalCadastroRapido').modal({
                backdrop: 'static',
                keyboard: false,
                show: true
            }).on('shown.bs.modal', function(e) {
                $('#campo_nome_rapido').focus();
            });
        };

        vm.fecharModal = function() {
            $('#modalCadastroRapido').modal('hide');
            $timeout(function() {
                $('#modalCadastroRapido').off();
            }, 1000);
        };

        /*var dados = {
            'nome': 'Marcelo'
        }
        vm.abrirModalCadastroRapido(dados, 'p');*/

        vm.aplicarScroll = function() {
            $('.checkBoxContainer').niceScroll();
        };

        vm.buscaCategorias = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                PessoasService.findCategorias(vm.saasid).then(function(response) {
                    vm.categorias = response.data;
                }, function(error) {
                    $log.error(error);
                });
            });
        };

        vm.ptbr = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Empresas'
        };
        vm.ptbr2 = {
            selectAll: 'Todos',
            selectNone: 'Limpar',
            search: 'Pesquisar...',
            nothingSelected: 'Cargos'
        };

        vm.efetuarCadastroRapido = function() {

            if (vm.dadosCadRapido.telefone == 55 || vm.dadosCadRapido.telefone == '55') {
                vm.dadosCadRapido.telefone = null;
            }

            if (vm.dadosCadRapido.telefone2 == 55 || vm.dadosCadRapido.telefone2 == '55') {
                vm.dadosCadRapido.telefone2 = null;
            }

            if (vm.dadosCadRapido.email != '') { // EMAIL PREENCHIDO, VALIDAR EMAIL
                var enviar = {
                    'email': vm.dadosCadRapido.email,
                    'saasid': vm.saasid
                };
                PessoasService.validaExisteEmail(enviar).then(function(response) {
                    if (response.data != 0) { // existe cliente com o email
                        if (response.data[0].tipo == 'p') {
                            swal({
                                title: 'E-mail já existente!',
                                text: 'Já existe um cliente cadastrado com o email informado:<br><br><a href=\'/pessoas/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                    response.data[0].nome + '</a>',
                                type: 'warning',
                                showConfirmButton: true,
                                html: true
                            }, function() {
                                $timeout(function() {
                                    $('#email').focus();
                                });
                            });
                        } else if (response.data[0].tipo == 'e') {
                            swal({
                                title: 'E-mail já existente!',
                                text: 'Já existe uma empresa cadastrada com o email informado:<br><br><a href=\'/empresas/info/' + response.data[0].slug + '\' target=\'_blank\' class=\'nome_cliente_existe_email\'>' +
                                    response.data[0].nome + '</a>',
                                type: 'warning',
                                showConfirmButton: true,
                                html: true
                            }, function() {
                                $timeout(function() {
                                    $('#email').focus();
                                });
                            });
                        }
                    } else { // não existe cliente com o email
                        vm.dadosCadRapido.salvar = true;
                        $('#modalCadastroRapido').modal('hide');
                    }
                }, function(error) {
                    $log.error(error);
                });
            } else { // EMAIL NÃO PREENCHIDO, CONTINUAR SEM VALIDAÇÃO
                vm.dadosCadRapido.salvar = true;
                $('#modalCadastroRapido').modal('hide');
            }
        };


        /*CONFIGURAÇÕES MODAL CADASTRO RAPIDO --- FIM*/


        //permite o filter funcionar sem ser necessário digitar os acentos para filtrar pela palavra com acentos
        vm.searchFn = function(actual, expected) {
            if (angular.isObject(actual)) return false;

            function removeAccents(value) {
                //substitui os acentos por letras normais
                return value.toString()
                    .replace(/á/g, 'a')
                    .replace(/à/g, 'a')
                    .replace(/ã/g, 'a')
                    .replace(/ä/g, 'a')
                    .replace(/â/g, 'a')
                    .replace(/é/g, 'e')
                    .replace(/è/g, 'e')
                    .replace(/ê/g, 'e')
                    .replace(/ë/g, 'e')
                    .replace(/í/g, 'i')
                    .replace(/ï/g, 'i')
                    .replace(/ì/g, 'i')
                    .replace(/î/g, 'i')
                    .replace(/ó/g, 'o')
                    .replace(/ô/g, 'o')
                    .replace(/õ/g, 'o')
                    .replace(/ö/g, 'o')
                    .replace(/ò/g, 'o')
                    .replace(/ú/g, 'u')
                    .replace(/ü/g, 'u')
                    .replace(/û/g, 'u')
                    .replace(/ù/g, 'u')
                    /*.replace(/ç/g, 'c')*/
                    .replace(/ß/g, 's');
            }
            actual = removeAccents(angular.lowercase('' + actual));
            expected = removeAccents(angular.lowercase('' + expected));

            return actual.indexOf(expected) !== -1;
        };

        $timeout(function() {
            $('.campo_prazo').daterangepicker({
                singleDatePicker: true,
                showDropdowns: true,
                locale: {
                    format: 'DD/MM/YYYY'
                },
                //minDate: new Date()
            });
        }, 100);

        vm.cadastrando = true;
        vm.update = false;

        vm.salvarNegocio = function() {

            function continuarSalvando() {

                vm.salvando = true;
                var validar = 0;
                // VALIDAÇÃO --- INÍCIO
                if (vm.negocios.nome == '') {
                    vm.validar.nome = true;
                    validar = 1;
                    vm.salvando = false;
                } else {
                    vm.validar.nome = false;
                }
                if (vm.negocios.cliente_empresa == '') {
                    vm.validar.cliente_empresa = true;
                    validar = 1;
                    vm.salvando = false;
                } else {
                    vm.validar.cliente_empresa = false;
                }
                /*if (vm.negocios.valor == '') {
                    vm.validar.valor = true;
                    validar = 1;
                    vm.salvando = false;
                } else {
                    vm.validar.valor = false;
                }*/
                if (vm.negocios.prazo == '') {
                    vm.validar.prazo = true;
                    validar = 1;
                    vm.salvando = false;
                } else {
                    vm.validar.prazo = false;
                }

                if (vm.negocios.responsavel == '') {
                    vm.validar.responsavel = true;
                    validar = 1;
                    vm.salvando = false;
                } else {
                    vm.validar.responsavel = false;
                }

                // VALIDAÇÃO --- FIM
                if (validar == 0) {
                    vm.negocios.qual_selecionado = $('#pessoa_empresa_selecionado').attr('value');
                    vm.salvando = true;
                    angular.element('#submit_negocio').html('<div class="spinner"></div>');
                    angular.element('#submit_negocio_responsivo').html('<div class="spinner"></div>');

                    if ($routeParams.slugNegocio) { // editando
                        vm.update = true;

                        if (Cookies.get('empresa_slug_update')) {
                            //se existe empresa_slug_update - cookies
                            if (vm.negocios.qual_selecionado == 'e') {
                                //se cliente selecionado for igual empresa
                                vm.verificarPE('e', '0');
                            } else {
                                //se for outra coisa cai aqui e executa o update normal oque no final vai para a tela de negociações geral e não para a tela de origem
                                vm.slugCerto = false;
                                vm.executarUpdate();
                            }
                        } else if (Cookies.get('pessoa_slug_update')) {
                            //se existe pessoa_slug_update - cookies
                            if (vm.negocios.qual_selecionado == 'p') {
                                //se cliente selecionado for igual pessoa
                                vm.verificarPE('p', '0');
                            } else {
                                //se for outra coisa cai aqui e executa o update normal oque no final vai para a tela de negociações geral e não para a tela de origem
                                vm.slugCerto = false;
                                vm.executarUpdate();
                            }
                        } else {
                            //se o update estiver sendo feito direto da tela e negociações gerais cai aqui
                            vm.slugCerto = false;
                            vm.executarUpdate();
                        }
                    } else { // cadastrando
                        if (Cookies.get('empresa_slug')) {
                            //se existe empresa_slug - cookies
                            if (vm.negocios.qual_selecionado == 'e') {
                                //se cliente selecionado for igual empresa
                                vm.verificarPE('e', '');
                            } else {
                                //se for outra coisa cai aqui e executa o cadastro normal oque no final vai para a tela de negociações geral e não para a tela de origem
                                vm.slugCerto = false;
                                vm.executarCad();
                            }
                        } else if (Cookies.get('pessoa_slug')) {
                            //se existe pessoa_slug - cookies
                            if (vm.negocios.qual_selecionado == 'p') {
                                //se cliente selecionado for igual pessoa
                                vm.verificarPE('p', '');
                            } else {
                                //se for outra coisa cai aqui e executa o cadastro normal oque no final vai para a tela de negociações geral e não para a tela de origem
                                vm.slugCerto = false;
                                vm.executarCad();
                            }
                        } else {
                            //se o cadastro estiver sendo feito direto da tela e negociações gerais cai aqui
                            vm.slugCerto = false;
                            vm.executarCad();
                        }
                    }
                }
            }

            if (vm.produto.prod_id) {
                swal({
                        title: 'Atenção!',
                        text: 'Verificamos que um produto foi selecionado, mas ainda não está adicionado a sua lista para ser salvo. Deseja continuar mesmo assim?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: 'Continuar',
                        cancelButtonText: 'Cancelar',
                        closeOnConfirm: true
                    },
                    function() {
                        continuarSalvando();
                    });
            } else {
                continuarSalvando();
            }


        };


        //EDITAR NEGÓCIO --- INÍCIO
        vm.editando = false;
        if ($routeParams.slugNegocio) {
            vm.cadastrarNegocio('editar');
            vm.editando = true;
            var dados = {
                'slug': $routeParams.slugNegocio
            };
            negociosService.carregarNegocio(dados).then(function(response) {
                //console.log(dados);
                //FactoryWebhook.triggerUpdate(vm.negocios.id);

                if (response.data[0].fk_cli_id) {
                    $('#pessoa_empresa_selecionado').attr('value', 'p');
                    var cliente_empresa = {
                        'id': response.data[0].fk_cli_id,
                        'nome': response.data[0].cli_nome,
                        'tipo': 'Pessoas',
                    };
                } else {
                    $('#pessoa_empresa_selecionado').attr('value', 'e');
                    var cliente_empresa = {
                        'id': response.data[0].fk_emp_id,
                        'nome': response.data[0].emp_nome,
                        'tipo': 'Empresas',
                    };
                }
                vm.buscaUsuarios(response.data[0].fk_user_id);
                vm.negocios.slug = $routeParams.slugNegocio;
                vm.negocios.nome = response.data[0].prop_nome;
                vm.negocios.cliente_empresa = cliente_empresa;
                vm.negocios.valor = response.data[0].prop_valor;
                vm.negocios.prazo = response.data[0].prop_prazo;
                $timeout(function() {
                    $('.campo_prazo').data('daterangepicker').setStartDate(response.data[0].prop_prazo);
                }, 100);
                vm.negocios.etapa = response.data[0].etapa_posicao;
                $('.grafico_interna_cadastro').attr('value', response.data[0].etapa_posicao);
                vm.etapaEscolhida = response.data[0].etapa_nome;
                vm.negocios.ganhou = response.data[0].prop_ganho;
                vm.negocios.perdeu = response.data[0].prop_perdido;
                vm.negocios.observacao = response.data[0].prop_observacao;
                if (response.data[0].fk_user_id != 0) {
                    vm.negocios.responsavel = response.data[0].fk_user_id;
                } else {
                    vm.negocios.responsavel = '';
                }
            }, function(error) {
                $log.error(error);
            });
        }

        if (!$routeParams.slugNegocio && !vm.cadastrar) { // mostra a lista de negociações quando não é edição ou cadastro
            $('.box_listagem_negocios').fadeIn(200);
        }

        //EDITAR NEGÓCIO --- FIM

        vm.acabouRepeatCadastro = function() {
            $timeout(function() {
                var qtd_etapas = vm.etapasLista.length;
                var largura_barra_etapas = $('.grafico_interna_cadastro').width();
                var tamanho_etapas = largura_barra_etapas / qtd_etapas;
                $('.etapas_cadastro').css('width', tamanho_etapas + 'px');
                $('.etapas_cadastro').fadeIn('slow');
            }, 200);
        };


        vm.excluirNegocio = function() {
            var dados = {
                'slug': $routeParams.slugNegocio
            };
            swal({
                    title: 'Deseja excluir este registro?',
                    text: 'Esta ação é irreversível',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não',
                    closeOnConfirm: false
                },
                function() {
                    $window.onkeydown = null;
                    $window.onfocus = null;
                    negociosService.apagarNegocio(dados).then(function(response) {
                        swal({
                            title: 'Removido!',
                            text: 'Negócio removido com sucesso!',
                            type: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        $timeout(function() {
                            //validação para saber para qual tela voltar depois que excluir pessoa/empresa
                            if (Cookies.get('pessoa_slug')) {
                                var slug = Cookies.get('pessoa_slug');
                                Cookies.remove('pessoa_slug');
                                $location.url('/pessoas/negocio/' + slug);
                            } else if (Cookies.get('empresa_slug')) {
                                var slug = Cookies.get('empresa_slug');
                                Cookies.remove('empresa_slug');
                                $location.url('/empresas/negocio/' + slug);
                            } else if (Cookies.get('pessoa_slug_update')) {
                                var slug = Cookies.get('pessoa_slug_update');
                                Cookies.remove('pessoa_slug_update');
                                $location.url('/pessoas/negocio/' + slug);
                            } else if (Cookies.get('empresa_slug_update')) {
                                var slug = Cookies.get('empresa_slug_update');
                                Cookies.remove('empresa_slug_update');
                                $location.url('/empresas/negocio/' + slug);
                            } else {
                                $location.url('/negocios');
                            }
                        }, 1000);
                    }, function(error) {
                        $log.error(error);
                    });
                });
        };

        vm.carregando_mais_inf = false;

        vm.carregando = true;

        vm.acabou = function() {
            vm.carregando = false;
            vm.carregando_mais_inf = false;
            $('.linha_listagem').removeClass('disable_a');
            vm.buscaEtapas();
            vm.tooltipsterNeg();
            vm.tooltipsterNegDESC();
            vm.tooltipsterMotivoPerda();
        };

        vm.tooltipsterNeg = function() {
            $timeout(function() {
                if (vm.mobile) {
                    var acao = 'click';
                    var posicao = 'left';
                } else {
                    var acao = 'hover';
                    var posicao = 'right';
                }
                $('.tooltipster_neg').tooltipster({
                    interactive: true,
                    trigger: acao,
                    maxWidth: 250,
                    minWidth: 250,
                    position: posicao,
                    theme: 'tooltipster-light',
                    delay: 100,
                });
            });
        };

        vm.tooltipsterNegDESC = function() {
            $timeout(function() {
                if (vm.mobile) {
                    var acao = 'click';
                    var posicao = 'left';
                } else {
                    var acao = 'hover';
                    var posicao = 'left';
                }
                $('.tooltipster_neg_desc').tooltipster({
                    interactive: true,
                    // trigger: acao,
                    maxWidth: 100,
                    minWidth: 100,
                    position: posicao,
                    theme: 'tooltipster-light',
                    delay: 100,
                });
            });
        };

        vm.tooltipsterMotivoPerda = function() {
            $timeout(function() {
                if (vm.mobile) {
                    var acao = 'click';
                    var posicao = 'left';
                } else {
                    var acao = 'hover';
                    var posicao = 'right';
                }
                $('.tooltipster_motivo_perda').tooltipster({
                    interactive: true,
                    // trigger: acao,
                    maxWidth: 250,
                    minWidth: 100,
                    position: posicao,
                    theme: 'tooltipster-light',
                    delay: 100,
                });
            });
        };

        vm.limite = 30;

        vm.carregar_tudo = true;
        vm.maisinf = function() {
            $('.linha_listagem').addClass('disable_a');
            vm.carregando_mais_inf = true;
            $timeout(function() {
                vm.limite = vm.limite + 20;
                vm.carregando_mais_inf = false;
            }, 500);
        };

        vm.carregarTítulo = function() {
            //carregar nome do cliente para aparecer como nome de título da pagina
            if ($location.url().match(/empresas/)) {
                negociosService.carregarNomeEmpresa($routeParams.slugCliente).then(function(response) {
                    $timeout(function() {
                        vm.titulo_pagina = response.data[0].emp_nome;
                        vm.ativo = response.data[0].emp_ativo;

                        vm.empresa = {
                            'nome': response.data[0].emp_nome,
                            'email': response.data[0].emp_email,
                            'ativo': response.data[0].emp_ativo,
                            'telefone': response.data[0].emp_telefone ? response.data[0].emp_telefone : 55,
                            'telefone2': response.data[0].emp_telefone2 ? response.data[0].emp_telefone2 : 55,
                            'slug': $routeParams.slugCliente,
                            'tipo': 'e'
                        };
                    });
                }, function(error) {
                    $log.error(error);
                });
            } else if ($location.url().match(/pessoas/)) {
                negociosService.carregarNomePessoa($routeParams.slugCliente).then(function(response) {
                    $timeout(function() {
                        vm.titulo_pagina = response.data[0].cli_nome;
                        vm.ativo = response.data[0].cli_ativo;

                        vm.cliente = {
                            'nome': response.data[0].cli_nome,
                            'email': response.data[0].cli_email,
                            'ativo': response.data[0].cli_ativo,
                            'telefone': response.data[0].cli_telefone ? response.data[0].cli_telefone : 55,
                            'telefone2': response.data[0].cli_telefone2 ? response.data[0].cli_telefone2 : 55,
                            'slug': $routeParams.slugCliente,
                            'tipo': 'p'
                        };
                    });
                }, function(error) {
                    $log.error(error);
                });
            }
        };
        $timeout(function() {
            vm.carregarTítulo();
        });

        vm.criarDados = function() {
            //criando cookies de validação para um cadastro
            if ($location.url().substring(0, 18) == '/empresas/negocio/') {
                $timeout(function() {
                    Cookies.set('empresa_slug', '' + vm.slugClienteN + '');
                    Cookies.remove('empresa_slug_update');
                    Cookies.remove('pessoa_slug');
                    Cookies.remove('pessoa_slug_update');
                });
            } else if ($location.url().substring(0, 17) == '/pessoas/negocio/') {
                $timeout(function() {
                    Cookies.set('pessoa_slug', '' + vm.slugClienteN + '');
                    Cookies.remove('empresa_slug');
                    Cookies.remove('empresa_slug_update');
                    Cookies.remove('pessoa_slug_update');
                });
            }
        };

        vm.criarDadosUpdate = function() {
            //criando cookies de validação para uma alteração
            if ($location.url().substring(0, 18) == '/empresas/negocio/') {
                $timeout(function() {
                    Cookies.set('empresa_slug_update', '' + vm.slugClienteN + '');
                });
            } else if ($location.url().substring(0, 17) == '/pessoas/negocio/') {
                $timeout(function() {
                    Cookies.set('pessoa_slug_update', '' + vm.slugClienteN + '');
                });


            }
        };

        vm.slugCerto = false;
        vm.verificarPE = function(tipo, x) {
            if (vm.update == false) {
                var negociosC = [];
                negociosC = angular.copy(vm.negocios);
                negociosC.cliente_empresa = '';
                negociosC.cliente_empresa = vm.negocios.cliente_empresa.id;
            }
            if (vm.update == true) {
                var dados = {
                    'tipo': tipo,
                    'negocios': vm.negocios,
                    'x': x
                };
            } else {
                var dados = {
                    'tipo': tipo,
                    'negocios': negociosC,
                    'x': x
                };
            }

            negociosService.verificarEPSelecionada(dados).then(function(response) {
                //retorno da verificação do banco se o cliente selecionado é igual de onde se originou o cadastro ou a alteração
                if (Cookies.get('empresa_slug') || Cookies.get('empresa_slug_update')) {
                    //se for igual ao de onde origiou o cadastro/alteração e for empresa cai aqui 
                    if (Cookies.get('empresa_slug') == response.data || Cookies.get('empresa_slug_update') == response.data) {
                        //aplica vm.slugCerto para afirmar que o cliente é o mesmo
                        vm.slugCerto = true;
                    } else {
                        //se não for o mesmo cliente cai aqui
                        vm.slugCerto = false;
                    }
                } else {
                    //o mesmo ocorre para o cadastro/alteração de pessoas
                    if (Cookies.get('pessoa_slug') == response.data || Cookies.get('pessoa_slug_update') == response.data) {
                        //cliente igual
                        vm.slugCerto = true;
                    } else {
                        //cliente diferente
                        vm.slugCerto = false;
                    }
                }
                if (vm.update == true) {
                    //se estiver ocorrendo uma alteração cai aqui
                    vm.executarUpdate();
                } else {
                    //se estiver ocorrendo um cadastro cai aqui
                    vm.executarCad();
                }

            }, function(error) {
                $log.error(error);
            });
        };


        vm.executarCad = function() {
            var negociosC = [];
            //valida se o campo cliente_empresa vem em forma de array ou não atráves do retorno de valor indefinido
            if (angular.isUndefined(vm.negocios.cliente_empresa.length)) {
                //se for indefinido ele cai aqui e aplica o valor do id par um campo unico chamado cliente_empresa, removendo o array que era retornado
                negociosC = angular.copy(vm.negocios);
                negociosC.cliente_empresa = '';
                negociosC.cliente_empresa = vm.negocios.cliente_empresa.id;
            } else {
                //se for definido, significa que o valor veio correto, dessa forma, ele apenas puxa o valor ja inserrido no array de edição
                negociosC = angular.copy(vm.negocios);
            }
            negociosC.responsavel = vm.responsavel.user_id;
            negociosC.user_hash = Cookies.get('crm_funil_hash');
            negociosC.saasid = vm.saasid;

            if (vm.produtosAdd != '') {
                negociosC.produtos = vm.produtosAdd;
            } else {
                negociosC.produtos = '';
            }

            negociosService.cadastrarNegocio(negociosC).then(function(response) {
                /*console.log("cadastrarNegocio");
                console.log(response.data);
                vm.salvando = false;
                return null;*/
                var id_prop = response.data.id;
                vm.integrarCompufacil(id_prop);

                FactoryWebhook.triggerCreate(id_prop);

                //validação depois de cadastrar a negociação
                if (vm.slugCerto == true) {
                    //se foi clicado para fazer o cadastro da empresa ou da pessoa, respectivamente através de suas telas (pessoas/empresas) ele verifica se o campo cliente/empresa no cadastro é igual ao nome da empresa/pessoa por onde foi aberto o novo cadastro, se for ele cai aqui, senão cai no else la em baixo
                    if (Cookies.get('empresa_slug')) {
                        //se foi cadastrada uma empresa direto da tela de empresa, cai aqui
                        swal({
                            title: 'Cadastrado com sucesso!',
                            type: 'success',
                            text: 'Proposta cadastrada com sucesso!',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        $timeout(function() {
                            vm.salvando = false;
                            angular.element('#submit_negocio').html('Salvar');
                            angular.element('#submit_negocio_responsivo').html('Salvar');
                            vm.limpar();
                            $('#campo_nome').focus();
                            var slug = Cookies.get('empresa_slug');
                            Cookies.remove('empresa_slug');
                            //$location.url('/empresas/negocio/' + slug);
                            $location.url('empresas/' + slug + '/negocio/info/' + response.data.slug);

                            var uri = API.url + '/empresas/' + slug + '/negocio/info/' + response.data.slug;

                            var respNotifId = negociosC.responsavel;
                            //var respNotNome = negociosC.responsavel_nome;

                            var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                            var localUdParse = JSON.parse(localUs);

                            var userLogId = localUdParse['0'].user_id;
                            var userLognome = localUdParse['0'].user_nome;

                            if (respNotifId != userLogId) {
                                // dispara a factory criar notificação
                                FactoryNotificacao.createNotificacao({
                                    "idUser": respNotifId,
                                    "origem": userLognome,
                                    "mensagem": 'cadastraNeg',
                                    "link": uri
                                })
                            }

                        }, 1000);
                    } else {
                        //senão automaticamente ele ja identifica como pessoa e sabe que o cadastro foi feito através da tela de pessoa, ai cai aqui
                        swal({
                            title: 'Cadastrado com sucesso!',
                            type: 'success',
                            text: 'Proposta cadastrada com sucesso!',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        $timeout(function() {
                            vm.salvando = false;
                            angular.element('#submit_negocio').html('Salvar');
                            angular.element('#submit_negocio_responsivo').html('Salvar');
                            vm.limpar();
                            $('#campo_nome').focus();
                            var slug = Cookies.get('pessoa_slug');
                            Cookies.remove('pessoa_slug');
                            /*$location.url('/pessoas/negocio/' + slug);*/
                            $location.url('pessoas/' + slug + '/negocio/info/' + response.data.slug);

                            var uri = API.url + '/pessoas/' + slug + '/negocio/info/' + response.data.slug;
                            var respNotifId = negociosC.responsavel;
                            var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                            var localUdParse = JSON.parse(localUs);
                            var userLogId = localUdParse['0'].user_id;
                            var userLognome = localUdParse['0'].user_nome;

                            if (respNotifId != userLogId) {
                                // dispara a factory criar notificação
                                FactoryNotificacao.createNotificacao({
                                    "idUser": respNotifId,
                                    "origem": userLognome,
                                    "mensagem": 'cadastraNeg',
                                    "link": uri
                                })
                            }

                        }, 1000);
                    }
                } else {
                    //se o cadastro estiver sendo feito de forma normal pela tela de negociações gerais ele cai aqui ou se não foi feito o cadastro de uma empresa que não seja a respectiva por onde ocorreu o acesso para o novo cadastro 
                    swal({
                        title: 'Cadastrado com sucesso!',
                        type: 'success',
                        text: 'Proposta cadastrada com sucesso!',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    $timeout(function() {
                        vm.salvando = false;
                        angular.element('#submit_negocio').html('Salvar');
                        angular.element('#submit_negocio_responsivo').html('Salvar');
                        $('#campo_nome').focus();
                        vm.cancelarCadastrarNegocio();
                        vm.buscaNegocios();
                        if (Cookies.get('pessoa_slug')) {
                            Cookies.remove('pessoa_slug');
                        }
                        if (Cookies.get('empresa_slug')) {
                            Cookies.remove('empresa_slug');
                        }
                        if (negociosC.qual_selecionado == 'p') {
                            $location.url('pessoas/' + vm.slugPE + '/negocio/info/' + response.data.slug);

                            var uri = API.url + '/pessoas/' + vm.slugPE + '/negocio/info/' + response.data.slug;
                            var respNotifId = negociosC.responsavel;
                            var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                            var localUdParse = JSON.parse(localUs);
                            var userLogId = localUdParse['0'].user_id;
                            var userLognome = localUdParse['0'].user_nome;

                            if (respNotifId != userLogId) {
                                // dispara a factory criar notificação
                                FactoryNotificacao.createNotificacao({
                                    "idUser": respNotifId,
                                    "origem": userLognome,
                                    "mensagem": 'cadastraNeg',
                                    "link": uri
                                })
                            }
                        } else {
                            $location.url('empresas/' + vm.slugPE + '/negocio/info/' + response.data.slug);

                            var uri = API.url + '/empresas/' + vm.slugPE + '/negocio/info/' + response.data.slug;
                            var respNotifId = negociosC.responsavel;
                            var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                            var localUdParse = JSON.parse(localUs);
                            var userLogId = localUdParse['0'].user_id;
                            var userLognome = localUdParse['0'].user_nome;

                            if (respNotifId != userLogId) {
                                // dispara a factory criar notificação
                                FactoryNotificacao.createNotificacao({
                                    "idUser": respNotifId,
                                    "origem": userLognome,
                                    "mensagem": 'cadastraNeg',
                                    "link": uri
                                })
                            }
                        }
                        vm.limpar();
                        //$location.url('/negocios');
                    }, 1000);
                }
            }, function(error) {
                $log.error(error);
            });
        };

        vm.executarUpdate = function() {
            vm.negocios.responsavel = vm.responsavel.user_id;
            vm.negocios.saasid = vm.saasid;


            negociosService.editarNegocio(vm.negocios).then(function(response) {
                //validação depois de relazilar o update da negociação
                if (vm.slugCerto == true) {
                    //se foi clicado para fazer o update da empresa ou da pessoa, respectivamente através de suas telas (pessoas/empresas) ele verifica se o campo cliente/empresa no update é igual ao nome da empresa/pessoa por onde foi aberto o alterar, se for ele cai aqui, senão cai no else la em baixo
                    if (Cookies.get('empresa_slug_update')) {
                        //se foi alterada uma empresa direto da tela de empresa, cai aqui
                        swal({
                            title: 'Alterado com sucesso!',
                            type: 'success',
                            text: 'Proposta alterada com sucesso!',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        $timeout(function() {
                            var slug = Cookies.get('empresa_slug_update');
                            Cookies.remove('empresa_slug_update');
                            $location.url('/empresas/negocio/' + slug);
                        }, 1000);


                        /*var uri = API.url + '/empresas/' + vm.slugPE + '/negocio/info/' + response.data.slug;
                        var respNotifId = negociosC.responsavel;
                        var localUs = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                        var localUdParse = JSON.parse(localUs);
                        var userLogId = localUdParse['0'].user_id;
                        var userLognome = localUdParse['0'].user_nome;

                        if (respNotifId != userLogId) {
                            // dispara a factory criar notificação
                            FactoryNotificacao.createNotificacao({
                                "idUser": respNotifId,
                                "origem": userLognome,
                                "mensagem": 'cadastraNeg',
                                "link": uri
                            })
                        }*/

                    } else {
                        //senão automaticamente ele ja identifica como pessoa e sabe que o alterar foi feito através da tela de pessoa, ai cai aqui
                        swal({
                            title: 'Alterado com sucesso!',
                            type: 'success',
                            text: 'Proposta alterada com sucesso!',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        $timeout(function() {
                            var slug = Cookies.get('pessoa_slug_update');
                            Cookies.remove('pessoa_slug_update');
                            $location.url('/pessoas/negocio/' + slug);
                        }, 1000);
                    }
                } else {
                    //se o alterar estiver sendo feito de forma normal pela tela de negociações gerais ele cai aqui ou se não foi feito o alterado de uma empresa que não seja a respectiva por onde ocorreu o acesso para o novo cadastro
                    swal({
                        title: 'Alterado com sucesso!',
                        type: 'success',
                        text: 'Proposta alterada com sucesso!',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    $timeout(function() {
                        if (Cookies.get('pessoa_slug_update')) {
                            Cookies.remove('pessoa_slug_update');
                        }
                        if (Cookies.get('empresa_slug_update')) {
                            Cookies.remove('empresa_slug_update');
                        }
                        $location.url('/negocios');
                    }, 1000);
                }
            }, function(error) {
                $log.error(error);
            });
        };

        vm.limparCookies = function() {
            //remove os cookies que não estão em uso para as validações 
            if (Cookies.get('pessoa_slug')) {
                Cookies.remove('pessoa_slug');
            }
            if (Cookies.get('empresa_slug')) {
                Cookies.remove('empresa_slug');
            }
            if (Cookies.get('pessoa_slug_update')) {
                Cookies.remove('pessoa_slug_update');
            }
            if (Cookies.get('empresa_slug_update')) {
                Cookies.remove('empresa_slug_update');
            }
        };

        /*ORDENAÇÃO DAS NEGOCIAÇÕES --- INÍCIO*/
        var executar_email = 0;
        vm.ordenarNegociacoes = function(qual1, filtrar_resp_email) {
            // console.log(qual1, filtrar_resp_email);

            /*VALIDAR SAAS --- INÍCIO*/
            vm.items = loadSaas(Cookies.get('crm_funil_hash'));
            vm.items.then(function(items) {
                vm.saasid = items;
                if ($routeParams.slugCliente) {
                    if ($location.url().match('/pessoas/')) {
                        var qual = 'pessoas';
                    } else {
                        var qual = 'empresas';
                    }
                    vm.items = validarSaas(qual, $routeParams.slugCliente, vm.saasid);
                    vm.items.then(function(existe) {
                        if (existe) { // saas id confere
                            vm.continuaordenarNegociacoes(qual1, filtrar_resp_email);
                        } else {
                            $window.history.back();
                        }
                    });
                } else {
                    vm.continuaordenarNegociacoes(qual1, filtrar_resp_email);
                }
            });
            /*VALIDAR SAAS --- FIM*/
        };

        var init = 0;
        var init6 = 0;
        vm.ordenacao = 'recentes';

        var first = true;
        //vm.filtro_situacao = '';
        vm.continuaordenarNegociacoes = function(qual, filtrar_resp_email) {
            vm.carregando = true;
            /*if ($routeParams.slugCliente) { // LISTAGEM INTERNA DE NEGOCIAÇÕES
                if ($location.url().substring(0, 18) == '/empresas/negocio/') { // LISTAGEM DE NEGOCIAÇÕES EM PESSOAS
                    var search = $location.search();
                    if (search.prazo && init == 0) {
                        var arrPrazo = search.prazo.split('_');
                        vm.inicio = moment(arrPrazo[0]);
                        vm.fim = moment(arrPrazo[1]);
                        $timeout(function() {
                            $('.reportrangeN').data('daterangepicker').setStartDate(vm.inicio);
                            $('.reportrangeN').data('daterangepicker').setEndDate(vm.fim);
                            $('.reportrangeN span').html(vm.inicio.format('D') + ' de ' + vm.inicio.format('MM') + '/' + vm.inicio.format('YY') + ' - ' + vm.fim.format('D') + ' de ' + vm.fim.format('MM') + '/' + vm.fim.format('YY'));
                        }, 200);
                    }
                    init = 1;

                    if (search.ordenar && init6 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA 
                        vm.ordenacao = search.ordenar;
                        qual = search.ordenar;
                    }

                    if (vm.inicio) {
                        var dados = {
                            'qual': qual,
                            'inicio': vm.inicio.format('YYYY-MM-DD'),
                            'fim': vm.fim.format('YYYY-MM-DD'),
                            'slug': $routeParams.slugCliente,
                            'saasid': vm.saasid
                        };
                        if (vm.zerouPrazo) {
                            vm.searchUrl('prazo', null);
                        } else {
                            vm.searchUrl('prazo', dados.inicio + "_" + dados.fim);
                        }
                    } else {
                        var dados = {
                            'qual': qual,
                            'slug': $routeParams.slugCliente,
                            'saasid': vm.saasid
                        };
                        vm.searchUrl('prazo', null);
                    }

                    if (qual == 'recentes') {
                        qual = null;
                    }
                    init6 = 1;


                    vm.searchUrl('ordenar', qual);

                    dados.user_hash = Cookies.get('crm_funil_hash');
                    dados.mod_id = 2;
                    negociosService.ordenarListaNegociacoesEmpresa(dados).then(function(response) {
                        if (response.data != 0) {
                            $timeout(function() {
                                vm.negociosLista = response.data;
                            });
                        } else {
                            $timeout(function() {
                                vm.negociosLista = '';
                            });
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                } else if ($location.url().substring(0, 17) == '/pessoas/negocio/') { // LISTAGEM DE NEGOCIAÇÕES EM EMPRESAS
                    var search = $location.search();
                    if (search.prazo && init == 0) {
                        var arrPrazo = search.prazo.split('_');
                        vm.inicio = moment(arrPrazo[0]);
                        vm.fim = moment(arrPrazo[1]);
                        $timeout(function() {
                            $('.reportrangeN').data('daterangepicker').setStartDate(vm.inicio.toString());
                            $('.reportrangeN').data('daterangepicker').setEndDate(vm.fim);
                            $('.reportrangeN span').html(vm.inicio.format('D') + ' de ' + vm.inicio.format('MM') + '/' + vm.inicio.format('YY') + ' - ' + vm.fim.format('D') + ' de ' + vm.fim.format('MM') + '/' + vm.fim.format('YY'));
                        }, 200);
                    }
                    init = 1;

                    if (search.ordenar && init6 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA 
                        vm.ordenacao = search.ordenar;
                        qual = search.ordenar;
                    }

                    if (vm.inicio) {
                        var dados = {
                            'qual': qual,
                            'inicio': vm.inicio.format('YYYY-MM-DD'),
                            'fim': vm.fim.format('YYYY-MM-DD'),
                            'slug': $routeParams.slugCliente,
                            'saasid': vm.saasid
                        };
                        if (vm.zerouPrazo) {
                            vm.searchUrl('prazo', null);
                        } else {
                            vm.searchUrl('prazo', dados.inicio + "_" + dados.fim);
                        }
                    } else {
                        var dados = {
                            'qual': qual,
                            'slug': $routeParams.slugCliente,
                            'saasid': vm.saasid
                        };
                        vm.searchUrl('prazo', null);
                    }

                    if (qual == 'recentes') {
                        qual = null;
                    }
                    init6 = 1;

                    vm.searchUrl('ordenar', qual);

                    dados.user_hash = Cookies.get('crm_funil_hash');
                    dados.mod_id = 2;
                    negociosService.ordenarListaNegociacoesPessoas(dados).then(function(response) {
                        if (response.data != 0) {
                            $timeout(function() {
                                vm.negociosLista = response.data;
                            });
                        } else {
                            $timeout(function() {
                                vm.negociosLista = '';
                            });
                        }
                    }, function(error) {
                        $log.error(error);
                    });
                }
            } else if ($routeParams.slugUserDash) { // ACESSO VIA CARDS SUPERIOR DO DASHBOARD
                vm.filtro_resp = $routeParams.slugUserDash;
                var search = $location.search();
                if (search.prazo && init == 0) {
                    var arrPrazo = search.prazo.split('_');
                    vm.inicio = moment(arrPrazo[0]);
                    vm.fim = moment(arrPrazo[1]);
                    $timeout(function() {
                        $('.reportrangeN').data('daterangepicker').setStartDate(vm.inicio);
                        $('.reportrangeN').data('daterangepicker').setEndDate(vm.fim);
                        $('.reportrangeN span').html(vm.inicio.format('D') + ' de ' + vm.inicio.format('MM') + '/' + vm.inicio.format('YY') + ' - ' + vm.fim.format('D') + ' de ' + vm.fim.format('MM') + '/' + vm.fim.format('YY'));
                    }, 200);
                }
                init = 1;

                if (search.ordenar && init6 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA 
                    vm.ordenacao = search.ordenar;
                    qual = search.ordenar;
                }

                if (vm.inicio) {
                    var dados = {
                        'qual': qual,
                        'inicio': vm.inicio.format('YYYY-MM-DD'),
                        'fim': vm.fim.format('YYYY-MM-DD'),
                        'slug': $routeParams.slugUserDash,
                        'saasid': vm.saasid
                    };
                    if (vm.zerouPrazo) {
                        vm.searchUrl('prazo', null);
                    } else {
                        vm.searchUrl('prazo', dados.inicio + "_" + dados.fim);
                    }
                } else {
                    var dados = {
                        'qual': qual,
                        'slug': $routeParams.slugUserDash,
                        'saasid': vm.saasid
                    };
                    vm.searchUrl('prazo', null);
                }

                if (qual == 'recentes') {
                    qual = null;
                }
                init6 = 1;

                vm.searchUrl('ordenar', qual);

                if ($routeParams.novos || $routeParams.ganhos || $routeParams.perdas) {
                    if ($routeParams.novos) {
                        var ultimos = 'novos';
                    } else if ($routeParams.ganhos) {
                        var ultimos = 'ganhos';
                    } else if ($routeParams.perdas) {
                        var ultimos = 'perdas';
                    }
                    var search = $location.search();
                    if (search.prazo && init == 0) {
                        var arrPrazo = search.prazo.split('_');
                        vm.inicio = moment(arrPrazo[0]);
                        vm.fim = moment(arrPrazo[1]);
                        $timeout(function() {
                            $('.reportrangeN').data('daterangepicker').setStartDate(vm.inicio);
                            $('.reportrangeN').data('daterangepicker').setEndDate(vm.fim);
                            $('.reportrangeN span').html(vm.inicio.format('D') + ' de ' + vm.inicio.format('MM') + '/' + vm.inicio.format('YY') + ' - ' + vm.fim.format('D') + ' de ' + vm.fim.format('MM') + '/' + vm.fim.format('YY'));
                        }, 200);
                    }
                    init = 1;

                    if (search.ordenar && init6 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA 
                        vm.ordenacao = search.ordenar;
                        qual = search.ordenar;
                    }

                    if (vm.inicio) {
                        var dados = {
                            'qual': qual,
                            'inicio': vm.inicio.format('YYYY-MM-DD'),
                            'fim': vm.fim.format('YYYY-MM-DD'),
                            'slug': $routeParams.slugUserDash,
                            'ultimos': ultimos,
                            'saasid': vm.saasid
                        };
                        if (vm.zerouPrazo) {
                            vm.searchUrl('prazo', null);
                        } else {
                            vm.searchUrl('prazo', dados.inicio + "_" + dados.fim);
                        }
                    } else {
                        var dados = {
                            'qual': qual,
                            'slug': $routeParams.slugUserDash,
                            'ultimos': ultimos,
                            'saasid': vm.saasid
                        };
                        vm.searchUrl('prazo', null);
                    }

                    if (qual == 'recentes') {
                        qual = null;
                    }
                    init6 = 1;

                    vm.searchUrl('ordenar', qual);
                    dados.user_hash = Cookies.get('crm_funil_hash');
                    dados.mod_id = 2;
                    negociosService.ordenarListaNegociacoesUsuarios(dados).then(function(response) {
                        //console.log('ordenarListaNegociacoesUsuarios: ',response.data);
                        if (response.data != 0) {
                            $timeout(function() {
                                vm.negociosLista = response.data;

                                if ($routeParams.ganhos) { // FILTRAR NEGOCIAÇÕES GANHAS - VINDO DO DASHBOARD
                                    $timeout(function() {
                                        vm.filtro_situacao = 'ganha';
                                        vm.filtrar_situacao(vm.filtro_situacao);
                                    });
                                } else if ($routeParams.perdas) { // FILTRAR NEGOCIAÇÕES PERDIDAS - VINDO DO DASHBOARD
                                    $timeout(function() {
                                        vm.filtro_situacao = 'perdida';
                                        vm.filtrar_situacao(vm.filtro_situacao);
                                    });
                                }
                            });
                        } else {
                            $timeout(function() {
                                vm.negociosLista = '';
                            });
                        }
                        var dados = {
                            'slug': $routeParams.slugUserDash
                        };
                    }, function(error) {
                        $log.error(error);
                    });
                } else {
                    dados.user_hash = Cookies.get('crm_funil_hash');
                    dados.mod_id = 2;
                    negociosService.ordenarListaNegociacoesUsuarios(dados).then(function(response) {
                        if (response.data != 0) {
                            $timeout(function() {
                                vm.negociosLista = response.data;
                            });
                        } else {
                            $timeout(function() {
                                vm.negociosLista = '';
                            });
                        }
                        var dados = {
                            'slug': $routeParams.slugUserDash
                        };
                    }, function(error) {
                        $log.error(error);
                    });
                }
            } else if ($routeParams.slugDoUserNotifi) { // ACESSO VIA NOTIFICAÇÃO DO USUÁRIO
                vm.filtro_resp = $routeParams.slugDoUserNotifi;
            } else { // LISTAGEM GERAL DE NEGOCIAÇÕES*/

            var search = $location.search();
            //if (search.prazo && init == 0) {
            if (search.prazo) {
                var arrPrazo = search.prazo.split('_');
                vm.inicio = moment(arrPrazo[0]);
                vm.fim = moment(arrPrazo[1]);
                $timeout(function() {
                    $('.reportrangeN').data('daterangepicker').setStartDate(vm.inicio);
                    $('.reportrangeN').data('daterangepicker').setEndDate(vm.fim);
                    $('.reportrangeN span').html(vm.inicio.format('DD') + '/' + vm.inicio.format('MM') + '/' + vm.inicio.format('YY') + ' - ' + vm.fim.format('DD') + '/' + vm.fim.format('MM') + '/' + vm.fim.format('YY'));
                }, 200);
            } else {
                vm.inicio = null;
                vm.fim = null;
                $('.reportrangeN span').html('Prazo');
            }
            //init = 1;

            if (search.ordenar && init6 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA 
                vm.ordenacao = search.ordenar;
                qual = search.ordenar;
            }

            /*if (vm.inicio) {
                var dados = {
                    'qual': qual,
                    'inicio': vm.inicio.format('YYYY-MM-DD'),
                    'fim': vm.fim.format('YYYY-MM-DD'),
                    'saasid': vm.saasid
                };
                if (vm.zerouPrazo) {
                    vm.searchUrl('prazo', null);
                } else {
                    vm.searchUrl('prazo', dados.inicio + "_" + dados.fim);
                }
            } else {
                var dados = {
                    'qual': qual,
                    'saasid': vm.saasid
                };
                vm.searchUrl('prazo', null);
            }*/

            var dados = {
                'qual': qual,
                'saasid': vm.saasid
            };

            if (vm.inicio) {
                dados.inicio = vm.inicio.format('YYYY-MM-DD');
                dados.fim = vm.fim.format('YYYY-MM-DD');
            }

            //vm.searchUrl('ordenar', qual);

            dados.user_hash = Cookies.get('crm_funil_hash');
            dados.mod_id = 2;

            if ($routeParams.slugCliente) { // LISTAGEM INTERNA DE NEGOCIAÇÕES
                if ($location.url().substring(0, 18) == '/empresas/negocio/') { // LISTAGEM DE NEGOCIAÇÕES EM PESSOAS
                    dados.pe = 'e';
                    dados.slug_pe = $routeParams.slugCliente;
                } else if ($location.url().substring(0, 17) == '/pessoas/negocio/') { // LISTAGEM DE NEGOCIAÇÕES EM EMPRESAS
                    dados.pe = 'p';
                    dados.slug_pe = $routeParams.slugCliente;
                }
            }

            if (search.periodo) { // ACESSO VIA CARDS SUPERIOR DO DASHBOARD
                dados.ultimos30 = true;
                vm.box_filtro_home = true;
            }

            /*FILTROS --- INICIO*/
            // var search = $location.search();
            if (search.ordenar) {
                vm.ordenacao = search.ordenar;
            } else {
                vm.ordenacao = 'recentes';
            }
            if (search.busca) {
                vm.buscando = true;
                vm.busca = search.busca;
            } else {
                vm.buscando = false;
                vm.busca = '';
            }
            if (search.situacao) {
                vm.filtro_situacao = search.situacao;
            } else {
                vm.filtro_situacao = '';
            }
            if (search.funil) {
                vm.filtro_etapa = search.funil;
            } else {
                vm.filtro_etapa = '';
            }
            if (search.responsavel) {
                vm.filtro_resp = search.responsavel;
            } else {
                vm.filtro_resp = '';
            }
            if (search.produto) {
                vm.filtro_produto = search.produto;
            } else {
                vm.filtro_produto = '';
            }

            dados.qual = vm.ordenacao;

            dados.filtro = {
                'situacao': vm.filtro_situacao,
                'funil': vm.filtro_etapa,
                'responsavel': vm.filtro_resp,
                'produto': vm.filtro_produto,
                'busca': vm.busca,
            };
            /*FILTROS --- FIM*/
            //aquiiiiiiii

            negociosService.ordenarListaNegociacoes(dados).then(function(response) {
                console.log('res neg GERAL: ', response.data);
                if (response.data != 0) {
                    $timeout(function() {
                        /*if (filtrar_resp_email == 'filtrar') { // executa na segunda vez que passa, para filtrar acesso via email
                            vm.negociosLista = response.data.dados;
                            vm.totalRegistros = response.data.total;
                            // console.log(response.data)
                            if ($routeParams.slugUsuarioEmail) {
                                vm.filtrar_responsavel($routeParams.slugUsuarioEmail);
                                vm.filtro_resp = $routeParams.slugUsuarioEmail;
                            } else if ($routeParams.slugUsuarioEmailAtrasado) {
                                vm.filtrar_responsavel($routeParams.slugUsuarioEmailAtrasado);
                                vm.filtro_resp = $routeParams.slugUsuarioEmailAtrasado;
                                vm.filtro_situacao = 'andamento';
                                vm.filtrar_situacao(vm.filtro_situacao);
                            }
                        }
                        if ($routeParams.slugUsuarioEmail) { // Acesso via email. Filtrar negociações do usuário e do dia selecionado
                            if (executar_email == 0) { // para executar somente uma vez o conteúdo abaixo
                                $timeout(function() {
                                    var data = $routeParams.dataSelecionada.split('-').join('/');
                                    $('.reportrangeN').data('daterangepicker').setStartDate(data);
                                    $('.reportrangeN').data('daterangepicker').setEndDate(data);
                                    var start = moment(data, 'DD/MM/YYYY');
                                    var end = moment(data, 'DD/MM/YYYY');
                                    $('.reportrangeN span').html(start.format('D') + '/' + start.format('MM') + '/' + start.format('YY') + ' - ' + end.format('D') + '/' + end.format('MM') + '/' + end.format('YY'));
                                    vm.inicio = start;
                                    vm.fim = end;
                                    vm.ordenarNegociacoes(vm.ordenacao, 'filtrar');
                                }, 100);
                                executar_email = 1;
                                delete $routeParams.slugUsuarioEmail;
                            }
                        } else if ($routeParams.slugUsuarioEmailAtrasado) {
                            if (executar_email == 0) {
                                vm.ordenacao = 'prazo_menor';
                                vm.ordenarNegociacoes(vm.ordenacao, 'filtrar');
                                executar_email = 1;
                            } else {
                                vm.negociosLista = response.data.dados;
                                vm.totalRegistros = response.data.total;
                            }
                        } else {
                            vm.negociosLista = response.data.dados;
                            vm.totalRegistros = response.data.total;
                        }*/
                        vm.negociosLista = response.data.dados;
                        vm.totalRegistros = response.data.total;
                    });
                } else {
                    $timeout(function() {
                        vm.negociosLista = '';
                        vm.totalRegistros = 0;
                    });
                }
            }, function(error) {
                $log.error(error);
            });
            //}
        };
        vm.ordenarNegociacoes(vm.ordenacao);
        /*ORDENAÇÃO DAS NEGOCIAÇÕES --- FIM*/

        /*BARRA DE FILTROS --- INÍCIO*/

        vm.buscaFiltro = function(qual) {
            if (!qual) {
                qual = null;
                vm.buscando = false;
            } else {
                vm.buscando = true;
            }

            vm.searchUrl('busca', qual);
            vm.ordenarNegociacoes();
        }

        $('.input_search').tooltipster({
            position: 'left',
            trigger: 'click',
            timer: 4000,
            theme: 'tooltipster-light'
        });

        vm.removerUltimos30 = function() {
            vm.searchUrl('periodo', null);
            vm.box_filtro_home = false;
        }

        /*ORDENACAO*/
        vm.ordenar = function(qual) {
            if (!qual || qual == 'recentes') {
                qual = null;
            }

            vm.searchUrl('ordenar', qual);
        }

        /*FILTRO SITUAÇÃO*/
        var init2 = 0;
        vm.filtrar_situacao = function(qual) {
            /*var search = $location.search();
            if (qual) {
              qual = qual;
            } else if (search.situacao && init2 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA 
              vm.filtro_situacao = search.situacao;
              qual = search.situacao;
            } else {
              qual = null;
              vm.filtro_situacao = '';
            }
            init2 = 1;

            var dados = angular.copy(vm.negociosLista);
            if (qual == 'ganha') {
              vm.filtro_situacao_g = 1;
              vm.filtro_situacao_p = 0;
            } else if (qual == 'perdida') {
              vm.filtro_situacao_g = 0;
              vm.filtro_situacao_p = 1;
            } else if (qual == 'andamento') {
              vm.filtro_situacao_g = 0;
              vm.filtro_situacao_p = 0;
            } else {
              delete vm.filtro_situacao_g;
              delete vm.filtro_situacao_p;
            }

            if ($routeParams.slugUsuarioEmailAtrasado) {
              if (dados) {
                var filtrados = $filter('filter')(dados, {
                  prop_ganho: vm.filtro_situacao_g,
                  prop_perdido: vm.filtro_situacao_p,
                  user_slug: $routeParams.slugUsuarioEmailAtrasado
                }).length;
              }
            } else {
              if (dados) {
                var filtrados = $filter('filter')(dados, {
                  prop_ganho: vm.filtro_situacao_g,
                  prop_perdido: vm.filtro_situacao_p
                }).length;
              }
            }

            $timeout(function() {
              if (filtrados < 30) {
                $('.mais_inf').hide();
              } else {
                $('.mais_inf').show();
              }
            });

            vm.searchUrl('situacao', qual);*/

            if (!qual) {
                qual = null;
            }

            vm.searchUrl('situacao', qual);
            //vm.ordenarNegociacoes();
        };
        //vm.filtrar_situacao();

        /*FILTRO RESPONSÁVEL*/
        var init4 = 0;
        vm.filtrar_responsavel = function(qual) {
            /*if ($routeParams.slugUserDash) {
                // console.log('entrouuu');
                if (qual) {
                    //$location.url('/negocios/user/' + qual);
                } else {
                    //$location.url('/negocios');
                }
            } else {

                var search = $location.search();
                if (qual) {
                    qual = qual;
                } else if (search.responsavel && init4 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA 
                    vm.filtro_responsavel = search.responsavel;
                    vm.filtro_resp = search.responsavel;
                    qual = search.responsavel;
                } else {
                    qual = null;
                    delete vm.filtro_responsavel;
                    delete vm.filtro_resp;
                }
                init4 = 1;

                var dados = angular.copy(vm.negociosLista);
                if (qual) {
                    vm.filtro_responsavel = qual;
                    if (vm.usuarios) {
                        var usuario = $filter('filter')(vm.usuarios, qual);
                        var nomeUser = usuario[0].user_nome;
                        if (usuario[0].user_ativo == 0) {
                            nomeUser += " (Inativo)";
                        }
                        $timeout(function() {
                            $('.filtro_resp').tooltip('destroy');
                            $timeout(function() {
                                $('.filtro_resp').tooltip({ 'title': nomeUser, 'placement': 'bottom' });
                            }, 300);
                        });
                    }
                } else {
                    delete vm.filtro_responsavel;
                    $timeout(function() {
                        $('.filtro_resp').tooltip('destroy');
                    });
                }

                if (dados) {
                    var filtrados = $filter('filter')(dados, {
                        user_slug: vm.filtro_responsavel
                    }).length;
                    if (filtrados < 30) {
                        $('.mais_inf').hide();
                    } else {
                        $('.mais_inf').show();
                    }
                }

                vm.searchUrl('responsavel', qual);
            }*/

            if (!qual) {
                qual = null;
                $timeout(function() {
                    $('.filtro_resp').tooltip('destroy');
                });
            } else {
                if (vm.usuarios) {
                    var usuario = $filter('filter')(vm.usuarios, qual);
                    var nomeUser = usuario[0].user_nome;
                    if (usuario[0].user_ativo == 0) {
                        nomeUser += " (Inativo)";
                    }
                    $timeout(function() {
                        $('.filtro_resp').tooltip('destroy');
                        $timeout(function() {
                            $('.filtro_resp').tooltip({ 'title': nomeUser, 'placement': 'bottom' });
                        }, 300);
                    });
                }
            }

            vm.searchUrl('responsavel', qual);
            //vm.ordenarNegociacoes();
        };

        /*FILTRO FUNIL ETAPAS*/
        var init3 = 0;
        vm.filtrar_funil = function(qual) {

            /*var search = $location.search();
            if (qual) {
                qual = qual;
            } else if (search.funil && init3 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA 
                vm.filtro_funil = search.funil;
                vm.filtro_etapa = search.funil;
                qual = search.funil;
            } else {
                qual = null;
                delete vm.filtro_funil;
                delete vm.filtro_etapa;
            }
            init3 = 1;

            var dados = angular.copy(vm.negociosLista);
            if (qual) {
                vm.filtro_funil = qual;
            } else {
                delete vm.filtro_funil;
            }
            if (dados) {
                var filtrados = $filter('filter')(dados, {
                    etapa_posicao: vm.filtro_funil
                }).length;
                if (filtrados < 30) {
                    $('.mais_inf').hide();
                } else {
                    $('.mais_inf').show();
                }
            }

            vm.searchUrl('funil', qual);*/

            if (!qual) {
                qual = null;
            }

            vm.searchUrl('funil', qual);
            //vm.ordenarNegociacoes();
        };
        //vm.filtrar_funil();

        /*FILTRO PRODUTOS*/
        var init5 = 0;
        vm.filtrar_produtos = function(qual) {
            /*var search = $location.search();
            if (qual) {
                qual = qual;
            } else if (search.produto && init5 == 0) { // EXECUTAR SOMENTE AO CARREGAR A PAGINA 
                vm.filtro_produto = search.produto
                qual = search.produto;
            } else {
                qual = null;
                delete vm.filtro_produto;
            }
            init5 = 1;

            var dados = angular.copy(vm.negociosLista);
            if (qual) {
                vm.filtro_produto = qual;
                if (vm.produtosLista) {
                    var produto = $filter('filter')(vm.produtosLista, qual);
                    var nomeProduto = produto[0].prod_nome;
                    $timeout(function() {
                        $('.filtro_produtos').tooltip('destroy');
                        $timeout(function() {
                            $('.filtro_produtos').tooltip({ 'title': nomeProduto, 'placement': 'bottom' });
                        }, 300);
                    });
                }
            } else {
                delete vm.filtro_produto;
                $timeout(function() {
                    $('.filtro_produtos').tooltip('destroy');
                });
            }

            if (dados) {
                var filtrados = $filter('filter')(dados,
                    vm.filtro_produto
                ).length;
                if (filtrados < 30) {
                    $('.mais_inf').hide();
                } else {
                    $('.mais_inf').show();
                }
            }

            vm.searchUrl('produto', qual);*/

            if (!qual) {
                qual = null;
                $timeout(function() {
                    $('.filtro_produtos').tooltip('destroy');
                });
            } else {
                if (vm.produtosLista) {
                    var produto = $filter('filter')(vm.produtosLista, qual);
                    var nomeProduto = produto[0].prod_nome;
                    $timeout(function() {
                        $('.filtro_produtos').tooltip('destroy');
                        $timeout(function() {
                            $('.filtro_produtos').tooltip({ 'title': nomeProduto, 'placement': 'bottom' });
                        }, 300);
                    });
                }
            }

            vm.searchUrl('produto', qual);
            //vm.ordenarNegociacoes();
        };

        /*FILTRO PRAZO*/
        vm.dateRangeN = function() {
            function cb(start, end) {
                if (start._isValid) { // datas validas
                    if (start.format('DD/MM/YYYY') == vm.inicial.format('DD/MM/YYYY') && end.format('DD/MM/YYYY') == vm.final.format('DD/MM/YYYY')) {
                        $('.reportrangeN span').html('Prazo');
                        vm.zerouPrazo = true;
                    } else {
                        //$('.reportrangeN span').html(start.format('D') + '/' + start.format('MM') + '/' + start.format('YY') + ' - ' + end.format('D') + '/' + end.format('MM') + '/' + end.format('YY'));
                        vm.zerouPrazo = false;
                    }
                    vm.inicio = start;
                    vm.fim = end;
                    //vm.ordenarNegociacoes(vm.ordenacao, start, end);

                    if (vm.zerouPrazo) {
                        vm.searchUrl('prazo', null);
                    } else {
                        vm.searchUrl('prazo', vm.inicio.format('YYYY-MM-DD') + "_" + vm.fim.format('YYYY-MM-DD'));
                    }
                } else {
                    $('.reportrangeN span').html('Prazo');
                    delete vm.inicio;
                    delete vm.fim;
                    //vm.ordenarNegociacoes(vm.ordenacao);
                    vm.searchUrl('prazo', null);
                }
            }

            $('.reportrangeN').daterangepicker({
                'opens': 'center',
                'locale': {
                    'format': 'DD/MM/YYYY'
                },
                ranges: {
                    'Prazo': [vm.inicial, vm.final],
                    'Hoje': [moment(), moment()],
                    'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Últ. 7 dias': [moment().subtract(6, 'days'), moment()],
                    'Últ. 30 dias': [moment().subtract(29, 'days'), moment()],
                    'Este mês': [moment().startOf('month'), moment().endOf('month')],
                    'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);
            $('.reportrangeN').data('daterangepicker').setStartDate(vm.inicial);
            $('.reportrangeN').data('daterangepicker').setEndDate(vm.final);
        };

        vm.dateRange = function() {
            function cb(start, end) {
                if (start._isValid) { // datas validas
                    if (start.format('DD/MM/YYYY') == vm.inicial.format('DD/MM/YYYY') && end.format('DD/MM/YYYY') == vm.final.format('DD/MM/YYYY')) {
                        $('.reportrange span').html('Prazo');
                        vm.zerouPrazo = true;
                    } else {
                        //$('.reportrange span').html(start.format('D') + '/' + start.format('MM') + '/' + start.format('YY') + ' - ' + end.format('D') + '/' + end.format('MM') + '/' + end.format('YY'));
                        vm.zerouPrazo = false;
                    }
                    vm.inicio = start;
                    vm.fim = end;
                    //vm.ordenarNegociacoes(vm.ordenacao, start, end);
                    if (vm.zerouPrazo) {
                        vm.searchUrl('prazo', null);
                    } else {
                        vm.searchUrl('prazo', vm.inicio.format('YYYY-MM-DD') + "_" + vm.fim.format('YYYY-MM-DD'));
                    }
                } else {
                    $('.reportrange span').html('Prazo');
                    delete vm.inicio;
                    delete vm.fim;
                    //vm.ordenarNegociacoes(vm.ordenacao);
                    vm.searchUrl('prazo', null);
                }
            }
            $('.reportrange').daterangepicker({
                'opens': 'center',
                ranges: {
                    'Prazo': [vm.inicial, vm.final],
                    'Hoje': [moment(), moment()],
                    'Ontem': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Últ. 7 dias': [moment().subtract(6, 'days'), moment()],
                    'Últ. 30 dias': [moment().subtract(29, 'days'), moment()],
                    'Este mês': [moment().startOf('month'), moment().endOf('month')],
                    'Mês passado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);
            $('.reportrange').data('daterangepicker').setStartDate(vm.inicial);
            $('.reportrange').data('daterangepicker').setEndDate(vm.final);
        };
        /*BARRA DE FILTROS --- FIM*/



        // CARREGAR PRIMEIRA E ULTIMO PRAZO DA LISTA DE COMPROMISSOS --- INÍCIO
        vm.buscarRange = function(table) {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) {
                vm.saasid = items;
                vm.items = rangeDatas('negocios', vm.saasid);
                vm.items.then(function(datas) {
                    vm.inicial = moment(datas.inicial, 'DD/MM/YYYY');
                    vm.final = moment(datas.final, 'DD/MM/YYYY');
                    /*if ($routeParams.slugCliente) {
                        vm.dateRange();
                    } else {
                        vm.dateRangeN();
                    }*/
                    vm.dateRangeN();

                });
            });
        };
        // CARREGAR PRIMEIRA E ULTIMO PRAZO DA LISTA DE COMPROMISSOS --- FIM

        /*if (!$location.url().match('/info/')) {
            vm.buscarRange();
        }*/
        vm.buscarRange();

        // FUNÇÃO ENVIAR E-MAIL NEGOCIAÇÕES --- INÍCIO
        $(window).ready(function() {
            $('#carregamento_principal').hide();
        });
        // FUNÇÃO ENVIAR E-MAIL NEGOCIAÇÕES --- FIM


        vm.validaAtrasado = function(data) {
            var dataatual = new Date();
            var d = dataatual.getDate();
            var m = dataatual.getMonth();
            m += 1;
            var y = dataatual.getFullYear();
            if (d < 10) {
                d = '0' + d;
            }
            if (m < 10) {
                m = '0' + m;
            }
            var data_formatada = (y + '-' + m + '-' + d);
            var atual = moment(data_formatada, 'YYYY-MM-DD');
            var escolhida = moment(data, 'YYYY-MM-DD');

            var diferenca = escolhida.diff(atual, 'days');
            /*if (escolhida.diff(atual) < 0) {*/
            /*if (diferenca < 0) {
                return true;
            } else {
                return false;
            }*/
            return diferenca;
        };

        // BOX INFORMAÇÕES DE NEGOCIAÇÕES - LATERAL DIRETA --- INÍCIO
        vm.mostrarGrafico = true;
        vm.carregarInfoGrafico = function() {
            var dados = {
                'slug': vm.slugClienteN
            };
            if ($location.path().match('/pessoas/')) {

                var rolIdDecry = API.funilRC.decrypt($window.localStorage.getItem('isModNegociacao'));
                var rolStorageObj = JSON.parse(rolIdDecry);
                var rolID = rolStorageObj.visualiza;
                //dadosUser
                var daUserStorage = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                var daUser = JSON.parse(daUserStorage);
                var gruId = daUser[0].fk_gru_id;
                var userId = daUser[0].user_id;

                dados.rolid = rolID;
                dados.userID = userId;
                dados.gruID = gruId;

                PessoasService.carregarValorNegocios(dados).then(function(response) {
                    if (response.data == '0') {
                        vm.negociosInfo = [{
                            'tipo': 'Abertos',
                            'valor': '0.00'
                        }, {
                            'tipo': 'Ganhos',
                            'valor': '0.00'
                        }, {
                            'tipo': 'Perdidos',
                            'valor': '0.00'
                        }, {
                            'tipo': 'Atrazados',
                            'atrazado': 'false'
                        }];
                    } else {
                        vm.negociosInfo = response.data;
                    }
                    var a = true;
                    var g = true;
                    var p = true;
                    var nome = '';
                    for (var x = 0; x < vm.negociosInfo.length; x++) {
                        if (x == 0) {
                            nome = 'neg_abertos';
                        } else if (x == 1) {
                            nome = 'neg_ganhos';
                        } else {
                            nome = 'neg_perdidos';
                        }
                        var soma = 0;
                        if (x == 0 && vm.negociosInfo[x].valor == '0.00') {
                            a = false;
                        } else if (x == 1 && vm.negociosInfo[x].valor == '0.00') {
                            g = false;
                        } else if (x == 2 && vm.negociosInfo[x].valor == '0.00') {
                            p = false;
                        }
                        for (var y = 0; y < vm.negociosInfo.length; y++) {
                            if (vm.negociosInfo[y].tipo != 'Atrazados') {
                                soma = soma + (parseFloat(vm.negociosInfo[y].valor));
                            }
                        }
                        if (vm.negociosInfo[x].tipo != 'Atrazados') {
                            var porcent = Math.round(parseInt(vm.negociosInfo[x].valor) / (soma / 100));
                            var valor = ((parseFloat(vm.negociosInfo[x].valor) / parseFloat(soma)) * 350);
                            if (valor == 0) {
                                $('.' + nome).css('display', 'none');
                            }
                            if (valor == 0) {
                                valor = valor + 3;
                            }
                            $('.' + nome).css('width', '' + valor + 'px');
                        }
                    }
                    //1 = ganhos      2 = perdidos
                    if (vm.negociosInfo[1].valor.length > 9 || vm.negociosInfo[2].valor.length > 9) {
                        $('.valor_ganhos').addClass('size_text_menor');
                        $('.valor_perdidos').addClass('size_text_menor');
                    } else {
                        $('.valor_ganhos').removeClass('size_text_menor');
                        $('.valor_perdidos').removeClass('size_text_menor');
                    }

                    if (a == true && p == true && g == true) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').addClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_block');
                        $('.neg_abertos').removeClass('display_none');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_block');
                        $('.neg_perdidos').removeClass('display_none');
                        //ganhos
                        $('.neg_ganhos').addClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_block');
                        $('.neg_ganhos').removeClass('display_none');
                    } else if (a == false && p == true && g == true) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').removeClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_none');
                        $('.neg_abertos').removeClass('display_block');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').addClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_block');
                        $('.neg_perdidos').removeClass('display_none');
                        //ganhos
                        $('.neg_ganhos').addClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_block');
                        $('.neg_ganhos').removeClass('display_none');
                    } else if (a == true && p == false && g == true) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').addClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_block');
                        $('.neg_abertos').removeClass('display_none');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_none');
                        $('.neg_perdidos').removeClass('display_block');
                        //ganhos
                        $('.neg_ganhos').addClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_block');
                        $('.neg_ganhos').removeClass('display_none');
                    } else if (a == true && p == true && g == false) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').addClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_block');
                        $('.neg_abertos').removeClass('display_none');
                        //perdidos
                        $('.neg_perdidos').addClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_block');
                        $('.neg_perdidos').removeClass('display_none');
                        //ganhos
                        $('.neg_ganhos').removeClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_none');
                        $('.neg_ganhos').removeClass('display_block');
                    } else if (a == false && p == false && g == true) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').removeClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_none');
                        $('.neg_abertos').removeClass('display_block');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_none');
                        $('.neg_perdidos').removeClass('display_block');
                        //ganhos
                        $('.neg_ganhos').removeClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').addClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_block');
                        $('.neg_ganhos').removeClass('display_none');
                    } else if (a == true && p == false && g == false) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').removeClass('border_radius_left');
                        $('.neg_abertos').addClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_block');
                        $('.neg_abertos').removeClass('display_none');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_none');
                        $('.neg_perdidos').removeClass('display_block');
                        //ganhos
                        $('.neg_ganhos').removeClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_none');
                        $('.neg_ganhos').removeClass('display_block');
                    } else if (a == false && p == true && g == false) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').removeClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_none');
                        $('.neg_abertos').removeClass('display_block');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').addClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_block');
                        $('.neg_perdidos').removeClass('display_none');
                        //ganhos
                        $('.neg_ganhos').removeClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_none');
                        $('.neg_ganhos').removeClass('display_block');
                    } else if (a == false && p == false && g == false) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').removeClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_none');
                        $('.neg_abertos').removeClass('display_block');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_none');
                        $('.neg_perdidos').removeClass('display_block');
                        //ganhos
                        $('.neg_ganhos').removeClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_none');
                        $('.neg_ganhos').removeClass('display_block');
                        vm.mostrarWarn = false;
                    }
                    $timeout(function() {
                        vm.porcent1 = Math.round(parseInt(vm.negociosInfo[1].valor) / (parseInt(vm.negociosInfo[0].valor) + parseInt(vm.negociosInfo[1].valor) + parseInt(vm.negociosInfo[2].valor)) * 100);
                        vm.porcent2 = Math.round(parseInt(vm.negociosInfo[2].valor) / (parseInt(vm.negociosInfo[0].valor) + parseInt(vm.negociosInfo[1].valor) + parseInt(vm.negociosInfo[2].valor)) * 100);
                        vm.total1 = (parseInt(vm.negociosInfo[0].valor) + parseInt(vm.negociosInfo[2].valor));
                        vm.total2 = (parseInt(vm.negociosInfo[0].valor) + parseInt(vm.negociosInfo[1].valor));
                        vm.vl1 = parseFloat(vm.negociosInfo[1].valor);
                        vm.vl2 = parseFloat(vm.negociosInfo[2].valor);
                        if (isNaN(parseInt(vm.porcent1))) {
                            vm.porcent1 = 0;
                            if (vm.total1 == 0) {
                                vm.total1 = 1;
                            }
                        }
                        if (isNaN(parseInt(vm.porcent2))) {
                            vm.porcent2 = 0;
                            if (vm.total2 == 0) {
                                vm.total2 = 1;
                            }
                        }

                        $timeout(function() {
                            var ctx = document.getElementById('myChart').getContext('2d');
                            var ctx2 = document.getElementById('myChart2').getContext('2d');
                            Chart.defaults.global.tooltips.enabled = false;

                            var myChart = new Chart(ctx, {
                                type: 'doughnut',
                                data: {
                                    labels: ['Total', 'Ganhos'],
                                    datasets: [{
                                        label: false,
                                        data: [vm.vl1, vm.total1],
                                        backgroundColor: ['white', '#94DF97'],
                                        borderColor: 'transparent',
                                        hoverBackgroundColor: ['white', '#94DF97']
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    cutoutPercentage: 80,
                                    legend: {
                                        display: false
                                    },
                                    plugins: {
                                        datalabels: {
                                            display: false
                                        }
                                    }
                                }
                            });

                            var myChart2 = new Chart(ctx2, {
                                type: 'doughnut',
                                data: {
                                    labels: ['', ''],
                                    datasets: [{
                                        label: '',
                                        data: [vm.vl2, vm.total2],
                                        backgroundColor: ['white', '#E7AFAF'],
                                        borderColor: 'transparent',
                                        hoverBackgroundColor: ['white', '#E7AFAF']
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    cutoutPercentage: 80,
                                    legend: {
                                        display: false
                                    },
                                    plugins: {
                                        datalabels: {
                                            display: false
                                        }
                                    }
                                },
                            });
                        });
                    });
                }, function(error) {
                    $log.error('Erro: ', error);
                });
            } else if ($location.path().match('/empresas/')) {

                var rolIdDecry = API.funilRC.decrypt($window.localStorage.getItem('isModNegociacao'));
                var rolStorageObj = JSON.parse(rolIdDecry);
                var rolID = rolStorageObj.visualiza;
                //dadosUser
                var daUserStorage = API.funilRC.decrypt($window.localStorage.getItem('5c479de2'));
                var daUser = JSON.parse(daUserStorage);
                var gruId = daUser[0].fk_gru_id;
                var userId = daUser[0].user_id;

                dados.rolid = rolID;
                dados.userID = userId;
                dados.gruID = gruId;
                empresasService.carregarValorNegocios(dados).then(function(response) {
                    if (response.data == '0') {
                        vm.negociosInfo = [{
                            'tipo': 'Abertos',
                            'valor': '0.00'
                        }, {
                            'tipo': 'Ganhos',
                            'valor': '0.00'
                        }, {
                            'tipo': 'Perdidos',
                            'valor': '0.00'
                        }, {
                            'tipo': 'Atrazados',
                            'atrazado': 'false'
                        }];
                    } else {
                        vm.negociosInfo = response.data;
                    }
                    var a = true;
                    var g = true;
                    var p = true;
                    var nome = '';
                    for (var x = 0; x < vm.negociosInfo.length; x++) {
                        if (x == 0) {
                            nome = 'neg_abertos';
                        } else if (x == 1) {
                            nome = 'neg_ganhos';
                        } else {
                            nome = 'neg_perdidos';
                        }
                        var soma = 0;
                        if (x == 0 && vm.negociosInfo[x].valor == '0.00') {
                            a = false;
                        } else if (x == 1 && vm.negociosInfo[x].valor == '0.00') {
                            g = false;
                        } else if (x == 2 && vm.negociosInfo[x].valor == '0.00') {
                            p = false;
                        }
                        for (var y = 0; y < vm.negociosInfo.length; y++) {
                            if (vm.negociosInfo[y].tipo != 'Atrazados') {
                                soma = soma + (parseFloat(vm.negociosInfo[y].valor));
                            }
                        }
                        if (vm.negociosInfo[x].tipo != 'Atrazados') {
                            var porcent = Math.round(parseInt(vm.negociosInfo[x].valor) / (soma / 100));
                            var valor = ((parseFloat(vm.negociosInfo[x].valor) / parseFloat(soma)) * 350);
                            if (valor == 0) {
                                $('.' + nome).css('display', 'none');
                            }
                            if (valor == 0) {
                                valor = valor + 3;
                            }
                            $('.' + nome).css('width', '' + valor + 'px');
                        }
                    }
                    //1 = ganhos      2 = perdidos
                    if (vm.negociosInfo[1].valor.length > 9 || vm.negociosInfo[2].valor.length > 9) {
                        $('.valor_ganhos').addClass('size_text_menor');
                        $('.valor_perdidos').addClass('size_text_menor');
                    } else {
                        $('.valor_ganhos').removeClass('size_text_menor');
                        $('.valor_perdidos').removeClass('size_text_menor');
                    }

                    if (a == true && p == true && g == true) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').addClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_block');
                        $('.neg_abertos').removeClass('display_none');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_block');
                        $('.neg_perdidos').removeClass('display_none');
                        //ganhos
                        $('.neg_ganhos').addClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_block');
                        $('.neg_ganhos').removeClass('display_none');
                    } else if (a == false && p == true && g == true) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').removeClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_none');
                        $('.neg_abertos').removeClass('display_block');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').addClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_block');
                        $('.neg_perdidos').removeClass('display_none');
                        //ganhos
                        $('.neg_ganhos').addClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_block');
                        $('.neg_ganhos').removeClass('display_none');
                    } else if (a == true && p == false && g == true) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').addClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_block');
                        $('.neg_abertos').removeClass('display_none');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_none');
                        $('.neg_perdidos').removeClass('display_block');
                        //ganhos
                        $('.neg_ganhos').addClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_block');
                        $('.neg_ganhos').removeClass('display_none');
                    } else if (a == true && p == true && g == false) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').addClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_block');
                        $('.neg_abertos').removeClass('display_none');
                        //perdidos
                        $('.neg_perdidos').addClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_block');
                        $('.neg_perdidos').removeClass('display_none');
                        //ganhos
                        $('.neg_ganhos').removeClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_none');
                        $('.neg_ganhos').removeClass('display_block');
                    } else if (a == false && p == false && g == true) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').removeClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_none');
                        $('.neg_abertos').removeClass('display_block');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_none');
                        $('.neg_perdidos').removeClass('display_block');
                        //ganhos
                        $('.neg_ganhos').removeClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').addClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_block');
                        $('.neg_ganhos').removeClass('display_none');
                    } else if (a == true && p == false && g == false) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').removeClass('border_radius_left');
                        $('.neg_abertos').addClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_block');
                        $('.neg_abertos').removeClass('display_none');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_none');
                        $('.neg_perdidos').removeClass('display_block');
                        //ganhos
                        $('.neg_ganhos').removeClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_none');
                        $('.neg_ganhos').removeClass('display_block');
                    } else if (a == false && p == true && g == false) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').removeClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_none');
                        $('.neg_abertos').removeClass('display_block');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').addClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_block');
                        $('.neg_perdidos').removeClass('display_none');
                        //ganhos
                        $('.neg_ganhos').removeClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_none');
                        $('.neg_ganhos').removeClass('display_block');
                    } else if (a == false && p == false && g == false) {
                        //abertos
                        $('.neg_abertos').removeClass('border_radius_right');
                        $('.neg_abertos').removeClass('border_radius_left');
                        $('.neg_abertos').removeClass('border_radius');
                        //display
                        $('.neg_abertos').addClass('display_none');
                        $('.neg_abertos').removeClass('display_block');
                        //perdidos
                        $('.neg_perdidos').removeClass('border_radius_right');
                        $('.neg_perdidos').removeClass('border_radius_left');
                        $('.neg_perdidos').removeClass('border_radius');
                        //display
                        $('.neg_perdidos').addClass('display_none');
                        $('.neg_perdidos').removeClass('display_block');
                        //ganhos
                        $('.neg_ganhos').removeClass('border_radius_right');
                        $('.neg_ganhos').removeClass('border_radius_left');
                        $('.neg_ganhos').removeClass('border_radius');
                        //display
                        $('.neg_ganhos').addClass('display_none');
                        $('.neg_ganhos').removeClass('display_block');
                        vm.mostrarWarn = false;
                    }
                    $timeout(function() {
                        vm.porcent1 = Math.round(parseInt(vm.negociosInfo[1].valor) / (parseInt(vm.negociosInfo[0].valor) + parseInt(vm.negociosInfo[1].valor) + parseInt(vm.negociosInfo[2].valor)) * 100);
                        vm.porcent2 = Math.round(parseInt(vm.negociosInfo[2].valor) / (parseInt(vm.negociosInfo[0].valor) + parseInt(vm.negociosInfo[1].valor) + parseInt(vm.negociosInfo[2].valor)) * 100);
                        vm.total1 = (parseInt(vm.negociosInfo[0].valor) + parseInt(vm.negociosInfo[2].valor));
                        vm.total2 = (parseInt(vm.negociosInfo[0].valor) + parseInt(vm.negociosInfo[1].valor));
                        vm.vl1 = parseFloat(vm.negociosInfo[1].valor);
                        vm.vl2 = parseFloat(vm.negociosInfo[2].valor);
                        if (isNaN(parseInt(vm.porcent1))) {
                            vm.porcent1 = 0;
                            if (vm.total1 == 0) {
                                vm.total1 = 1;
                            }
                        }
                        if (isNaN(parseInt(vm.porcent2))) {
                            vm.porcent2 = 0;
                            if (vm.total2 == 0) {
                                vm.total2 = 1;
                            }
                        }

                        $timeout(function() {
                            var ctx = document.getElementById('myChart').getContext('2d');
                            var ctx2 = document.getElementById('myChart2').getContext('2d');
                            Chart.defaults.global.tooltips.enabled = false;

                            var myChart = new Chart(ctx, {
                                type: 'doughnut',
                                data: {
                                    labels: ['Total', 'Ganhos'],
                                    datasets: [{
                                        label: false,
                                        data: [vm.vl1, vm.total1],
                                        backgroundColor: ['white', '#94DF97'],
                                        borderColor: 'transparent',
                                        hoverBackgroundColor: ['white', '#94DF97']
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    cutoutPercentage: 80,
                                    legend: {
                                        display: false
                                    },
                                    plugins: {
                                        datalabels: {
                                            display: false
                                        }
                                    }
                                }
                            });

                            var myChart2 = new Chart(ctx2, {
                                type: 'doughnut',
                                data: {
                                    labels: ['', ''],
                                    datasets: [{
                                        label: '',
                                        data: [vm.vl2, vm.total2],
                                        backgroundColor: ['white', '#E7AFAF'],
                                        borderColor: 'transparent',
                                        hoverBackgroundColor: ['white', '#E7AFAF']
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    cutoutPercentage: 80,
                                    legend: {
                                        display: false
                                    },
                                    plugins: {
                                        datalabels: {
                                            display: false
                                        }
                                    }
                                },
                            });
                        });
                    });
                }, function(error) {
                    $log.error('Erro: ', error);
                });
            }
        };
        vm.carregarInfoGrafico();

        vm.tooltipBullet = 0;
        vm.posicionarTooltip = function(x) {
            if (x == 'neg_abertos') {
                vm.tooltipBullet = vm.negociosInfo[0].valor;
            } else if (x == 'neg_ganhos') {
                vm.tooltipBullet = vm.negociosInfo[1].valor;
            } else if (x == 'neg_perdidos') {
                vm.tooltipBullet = vm.negociosInfo[2].valor;
            } else {
                vm.tooltipBullet = 0;
            }
            var height = $('.' + x).height();
            var width = $('.' + x).width();
            var left = $('.' + x).offset().left;
            var top = $('.' + x).offset().top;
            $('.tooltip_bullet').css('margin-left', '' + ((left + width) - 3) + 'px');
            $('.tooltip_bullet').css('margin-top', '' + (top + height + 4) + 'px');
            $('.tooltip_bullet').css('display', 'block');
            vm.tooltipBulletCurrency = $filter('currency')(vm.tooltipBullet, 'R$');
            $('#tooltip_grafic').html(vm.tooltipBulletCurrency);
        };

        vm.setarDisplayNone = function() {
            $('.tooltip_bullet').css('display', 'none');
        };
        // BOX INFORMAÇÕES DE NEGOCIAÇÕES - LATERAL DIRETA --- FIM 



        /*FUNIL DE NEGOCIAÇÕES --- INÍCIO*/
        vm.listarEtapasFunil = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                    'user_hash': Cookies.get('crm_funil_hash'),
                    'mod_id': 2,
                    'funil_venda': vm.funil_venda_grafico
                };
                negociosService.carregarEtapasKanban(dados).then(function(response) {
                    if (response.data != 0) {
                        $timeout(function() {
                            vm.etapasFunilLista = response.data;
                            vm.totalNegAndamento = 0;
                            var qtd = response.data.length;
                            for (var a = 0; a < qtd; a++) {
                                vm.totalNegAndamento = vm.totalNegAndamento + parseFloat(response.data[a].valor_total);
                            }
                            vm.initFunnel(vm.etapasFunilLista);
                        });
                    } else {
                        $timeout(function() {
                            vm.etapasFunilLista = '';
                        });
                    }
                }, function(error) {
                    $log.error(error);
                });
            });
        };
        //vm.listarEtapasFunil();

        vm.acabouRepeatFunil = function() {
            var qtd_etapas = vm.etapasFunilLista.length;
            $timeout(function() {
                $('[data-toggle="tooltip_funil"]').tooltip();
                /*var etapa = 2;
                var largura = 92;
                for (var i = 0; i < qtd_etapas; i++) {
                    $(".etapa_funil_" + etapa).css("width", largura + "%");
                    etapa++;
                    largura = largura - 8;
                }*/
            });
            $timeout(function() {
                var a = 1;
                angular.forEach(vm.etapasFunilLista, function(value, key) {
                    var altura_box_img = $('.img_etapa_' + value.etapa_id).height();
                    var largura_box_img = $('.img_etapa_' + value.etapa_id).width();
                    var largura_img = $('.img_' + value.etapa_id).width();
                    var altura_img = $('.img_' + value.etapa_id).height();

                    var left = (largura_box_img / 2) - (largura_img / 2) + 0;
                    $('.img_etapa_' + value.etapa_id).height();
                    $('.texto_etapa_' + value.etapa_id).css('padding-top', altura_box_img / 2 - 8 + 'px');
                    $('.box_texto_' + value.etapa_id).css('width', largura_img + 'px');
                    $('.box_texto_' + value.etapa_id).css('height', altura_img + 'px');
                    $('.box_texto_' + value.etapa_id).css('left', left + 'px');
                    if (vm.etapasFunilLista.length == a) {
                        $('.t').fadeIn('fast');
                    }
                    a++;
                });
            }, 800);
        };
        /*FUNIL DE NEGOCIAÇÕES --- FIM*/

        vm.tamanhoMenuMobile = function() {
            $('.box_menu_interno_mobile').css('width', screen.width - 30 + 'px');
        };

        vm.mobile = false;
        $(function() {
            if (screen.width < 767) {
                vm.mobile = true;
            } else {
                vm.mobile = false;
            }
        });

        if (vm.mobile) {
            $('.box_filtros_mobile').hide();
        }

        vm.mostrarFiltros = function() {
            $('.box_filtros_mobile').slideToggle('slow');
            $('.box_filtro_mobile').slideToggle('slow');
        };

        vm.nomeEtapa = function(posicao) {
            var nome = '';
            vm.etapasLista.forEach(function(element) {
                if (element.etapa_posicao == posicao) {
                    nome = element.etapa_nome;
                }
            }, this);
            return nome;
        };

        vm.ativo = 1;

        /*ADICIONAR PRODUTOS --- INÍCIO*/
        vm.seta_produtos = true;
        vm.acc_produtos = function() {
            vm.seta_produtos = !vm.seta_produtos;
            $('.box_campos_produtos').fadeToggle();
            $('.box_produtos_add').fadeToggle();
        };

        vm.buscarProdutos = function() {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function(items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid
                };
                produtosService.findAll(dados).then(function(response) {
                    if (response.data != 0) {
                        $timeout(function() {
                            vm.produtosLista = response.data;
                        });
                    } else {
                        $timeout(function() {
                            vm.produtosLista = '';
                        });
                    }
                    //vm.filtrar_produtos();
                }, function(error) {
                    $log.error(error);
                });
            });
        };
        //vm.acc_produtos();

        vm.produtosAdd = [];
        vm.produto = [];
        vm.prod_qtd = 1;
        vm.limparProduto = function() {
            vm.produtosAdd = [];
        };

        vm.growableOptions4 = {
            displayText: 'Produtos',
            emptyListText: 'Nenhum produto cadastrado',
            onAdd: function(texto) {
                var deferred = $q.defer();
                vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
                vm.items.then(function(items) {
                    vm.saasid = items;
                    vm.leads = verificaLeads(vm.saasid); // carregar saas id
                    var dados = {
                        'nome': texto,
                        'saasid': vm.saasid
                    };
                    $('.check_cadastro2').hide(); // remove o check
                    $('.spinner_cadastro2').toggleClass('hide'); // aparecer loading
                    produtosService.cadastrarProdutoRapido(dados).then(function(response) {
                        vm.buscarProdutos();
                        $('.spinner_cadastro2').toggleClass('hide'); // remover loading
                        $('.check_cadastro2').fadeIn('slow'); // mostra check sucesso
                        $('.limpar').val(''); // limpar input após cadastro
                        $timeout(function() {
                            $('.check_cadastro2').fadeOut('slow'); // após 2 segundos remove o check
                        }, 2000);
                        var produto = {
                            'prod_id': response.data[0].prod_id,
                            'prod_nome': response.data[0].prod_nome
                        };
                        deferred.resolve(produto);
                    }, function(error) {
                        $log.error(error);
                    });
                });
                return deferred.promise;
            }
        };

        vm.error_produto = false;
        vm.adicionarProduto = function() {
            var dados = {
                'prod_id': vm.produto.prod_id,
                'prod_nome': vm.produto.prod_nome,
                'prod_qtd': vm.prod_qtd,
                'prod_valor': 0
            };
            var existe = 0;
            angular.forEach(vm.produtosAdd, function(value, key) {
                if (value.prod_id == vm.produto.prod_id) {
                    existe = 1;
                }
            });
            if (existe == 1) {
                $timeout(function() {
                    vm.error_produto = true;
                    $timeout(function() {
                        vm.error_produto = false;
                    }, 2000);
                });
            } else {
                vm.produtosAdd.push(dados);
                if (vm.negocios.valor && vm.negocios.valor != 0) {
                    var quant = vm.produtosAdd.length;
                    angular.forEach(vm.produtosAdd, function(value, key) {
                        $timeout(function() {
                            if (vm.valorPadraoAlterado || !vm.utilizarValores) {
                                if (quant == 0) {
                                    vm.produtosAdd[key].prod_valor = vm.negocios.valor;
                                } else {
                                    vm.produtosAdd[key].prod_valor = parseFloat(parseFloat(vm.negocios.valor) / quant);
                                }
                            }
                        });
                    });
                }
                vm.produto = [];
                vm.prod_qtd = 1;
                vm.error_produto = false;
            }
        };

        vm.removerProdutos = function(index) {
            vm.produtosAdd.splice(index, 1);
            if (vm.negocios.valor && vm.negocios.valor != 0) {
                var quant = vm.produtosAdd.length;
                angular.forEach(vm.produtosAdd, function(value, key) {
                    $timeout(function() {
                        if (vm.valorPadraoAlterado) {
                            if (quant == 0) {
                                vm.produtosAdd[key].prod_valor = vm.negocios.valor;
                            } else {
                                vm.produtosAdd[key].prod_valor = parseFloat(parseFloat(vm.negocios.valor) / quant);
                            }
                        } else { // ATUALIZA VALORES
                            var valor = 0;
                            angular.forEach(vm.produtosAdd, function(value, key) {
                                valor = parseFloat(parseFloat(valor) + parseFloat(value.prod_valor));
                            });
                            vm.negocios.valor = parseFloat(valor);
                        }
                    });
                });
            }
            if (vm.produtosAdd == '' && !vm.valorPadraoAlterado) {
                vm.negocios.valor = '';
                vm.desabilitarValor = false;
                vm.checkUtilizarValores = false;
                vm.utilizarValoresProdutos();
            }
        };


        // INICIO QUANTIDADE
        vm.initQuantidadeProd = function(i) {
            var idSelectorSpan = String('#SpanEditQtdProd_' + i);
            var idSelectorInput = String('#InputEditQtdProd_' + i);

            setTimeout(function() {
                angular.element(idSelectorSpan).show()
                angular.element(idSelectorInput).hide()
            }, 10);
            return true;

        }

        vm.editarQuantidadeProd = function(i, produto) {
            var idSelectorSpan = String('#SpanEditQtdProd_' + i);
            var idSelectorInput = String('#InputEditQtdProd_' + i);


            setTimeout(function() {
                angular.element(idSelectorSpan).hide()
                angular.element(idSelectorInput).show()
                angular.element(idSelectorInput).focus()

                // console.log(idSelectorSpan, idSelectorInput);
                // console.log(i, produto);
            }, 10);
        }

        vm.blurQuantidadeProd = function(i) {
            var idSelectorSpan = String('#SpanEditQtdProd_' + i);
            var idSelectorInput = String('#InputEditQtdProd_' + i);

            setTimeout(function() {
                angular.element(idSelectorSpan).show()
                angular.element(idSelectorInput).hide()
            }, 10);

        }

        vm.updateQuantidadeProd = function(i, qtd) {
            vm.produtosAdd[i].prod_qtd = qtd;
        }

        // FINAL QUANTIDADE

        vm.valorPadraoAlterado = false;
        vm.alterarValorPadrao = function() {
            if (vm.negocios.valor && vm.negocios.valor != 0) {
                vm.valorPadraoAlterado = true;
                var quant = vm.produtosAdd.length;
                angular.forEach(vm.produtosAdd, function(value, key) {
                    $timeout(function() {
                        if (vm.valorPadraoAlterado) {
                            if (quant == 1) {
                                vm.produtosAdd[key].prod_valor = vm.negocios.valor;
                            } else {
                                vm.produtosAdd[key].prod_valor = parseFloat(parseFloat(vm.negocios.valor) / quant);
                            }
                        }
                    });
                });
            } else {
                vm.valorPadraoAlterado = false;
            }
        };

        vm.atualizarValor = function() {
            $timeout(function() {
                if (!vm.valorPadraoAlterado || vm.utilizarValores) { // SE NÃO FOI DIGITADO UM VALOR NO CAMPO PADRÃO, ATUALIZA CONFORME O VALOR DOS PRODUTOS
                    var valor = 0;
                    angular.forEach(vm.produtosAdd, function(value, key) {
                        valor = parseFloat(parseFloat(valor) + parseFloat(value.prod_valor)); // SOMAR VALORES
                    });
                    vm.negocios.valor = parseFloat(valor);
                }
            });
        };

        vm.checkUtilizarValores = false;
        vm.utilizarValores = false;
        $('.campo_valor').hide();
        vm.utilizarValoresProdutos = function() {
            if (vm.checkUtilizarValores) { // MARCADO
                $timeout(function() {
                    vm.utilizarValores = true;
                    vm.desabilitarValor = true;
                    $timeout(function() {
                        $('.txt_valor').hide();
                        $('.campo_valor').fadeIn();
                        if (vm.negocios.valor && vm.negocios.valor != 0) {
                            var quant = vm.produtosAdd.length;
                            angular.forEach(vm.produtosAdd, function(value, key) {
                                $timeout(function() {
                                    if (vm.valorPadraoAlterado) {
                                        if (quant == 0) {
                                            vm.produtosAdd[key].prod_valor = vm.negocios.valor;
                                        } else {
                                            vm.produtosAdd[key].prod_valor = parseFloat(parseFloat(vm.negocios.valor) / quant);
                                        }
                                    }
                                });
                            });
                        }
                    }, 100);
                });
            } else { // NÃO MARCADO
                $timeout(function() {
                    vm.utilizarValores = false;
                    vm.desabilitarValor = false;
                    $('.campo_valor').hide();
                    $('.txt_valor').fadeIn();
                });
            }
        };

        vm.desabilitarValor = false;
        vm.tooltipster = function() {
            if (vm.mobile) {
                var acao = 'click';
                var posicao = 'top';
            } else {
                var acao = 'hover';
                var posicao = 'right';
            }
            $('.icon_ajuda_prod').tooltipster({
                interactive: true,
                trigger: acao,
                maxWidth: 300,
                position: posicao,
            });
        };
        /*ADICIONAR PRODUTOS --- FIM*/


        vm.marcarEtapaMobile = function(prop_id, etapa_id) {
            var dados = {
                'prop_id': prop_id,
                'etapa_id': etapa_id,
                'user_hash': Cookies.get('crm_funil_hash')
            };
            $('.check_etapa_' + prop_id).hide(); // remove o check
            $('.spinner_etapa_' + prop_id).toggleClass('hide'); // aparecer loading
            negociosService.marcarEtapa(dados).then(function(response) {
                $('.spinner_etapa_' + prop_id).toggleClass('hide'); // remover loading
                $('.check_etapa_' + prop_id).fadeIn('slow'); // mostra check sucesso
                $timeout(function() {
                    $('.check_etapa_' + prop_id).fadeOut('slow'); // após 2 segundos remove o check
                }, 2000);
            }, function(error) {
                $log.error(error);
            });
        };

        vm.mostrarBtnEditar = false;
        vm.verifMostrarBtnEditar = function() {
            var local = window.localStorage.getItem('5c479de2');
            var valLocal = JSON.parse(API.funilRC.decrypt(local));
            var localPessoas = window.localStorage.getItem('isModNegociacao');
            var valLocalPessoas = JSON.parse(API.funilRC.decrypt(localPessoas));
            switch (parseInt(valLocalPessoas.altera)) {
                case 4:
                    $timeout(function() {
                        vm.mostrarBtnEditar = false;
                        vm.roleSemAcesso = true;
                    });
                    break;
                case 3:
                    if (valLocal[0].user_id == vm.responsavel.user_id) {
                        $timeout(function() {
                            vm.mostrarBtnEditar = true;
                            vm.userIdRole = valLocal[0].user_id;
                        });
                    } else {
                        $timeout(function() {
                            vm.mostrarBtnEditar = false;
                        });
                    };
                    break;
                case 2:
                    if (valLocal[0].fk_gru_id == vm.responsavel.fk_gru_id) {
                        $timeout(function() {
                            vm.mostrarBtnEditar = true;
                            vm.grupoIdRole = valLocal[0].fk_gru_id;
                        });
                    } else {
                        $timeout(function() {
                            vm.mostrarBtnEditar = false;
                        });
                    };
                    break;
                default:
                    $timeout(function() {
                        vm.mostrarBtnEditar = true;
                    });
            }
        }

        function verifSearch() {
            /*var search = $location.search();
            if ($location.url() == '/negocios') {
              if (!search.prazo && !search.situacao && !search.funil && !search.responsavel && !search.produto && !search.ordenar && !search.busca) {
                  vm.filtro_situacao = '';
                  vm.filtro_etapa = '';
                  vm.filtro_resp = '';
                  vm.filtro_produto = '';
                  vm.busca = '';
                  vm.buscando = false;
                  //vm.ordenarNegociacoes('recentes');
                  //vm.ordenarNegociacoes();
              }
            }*/
            if ((!$location.url().match('/info/') && $location.url().match('/negocios')) || $location.url().match('/negocio/')) {
                vm.ordenarNegociacoes();
            }
        }
        $scope.$on("$locationChangeSuccess", verifSearch);




        /*MOTIVO PERDA DA NEGOCIAÇÃO --- INICIO*/
        vm.modalMotivoNeg = function(Prop, $event) {
            FactoryModalMotivo.openModal(Prop, $event);


            $rootScope.$on('atualizar_negociacoes', function(event, data) {
                $timeout(function() {
                    vm.continuaordenarNegociacoes();
                });
            });
        }
        /*MOTIVO PERDA DA NEGOCIAÇÃO --- FIM*/


        /*NOVO FUNIL DE VENDAS COM ETAPAS DINÂMICAS --- INÍCIO */
        vm.initFunnel = function(dados) {
            const data = [];

            angular.forEach(dados, function(value, key) {
                if (key % 2 == 0) { // PAR
                    //var cor = '#1f77b4'; // azul claro
                    //var cor = '#1a7568'; // verde
                    var cor = '#1b6498'; // azul escuro
                } else { // IMPAR
                    //var cor = '#3d94d0'; // azul claro
                    //var cor = '#208b7c'; // verde
                    var cor = '#13476c'; // azul escuro
                }
                data.push({
                    'label': value.etapa_nome,
                    'value': $filter('currency')(value.valor_total, 'R$ ') + '\n' + value.qtd_propostas,
                    'backgroundColor': cor
                });
            });

            if (dados.length <= 3){
                var height = dados.length * 130;
                var bottomWidth = 1 / 1.5;
            } else {
                var height = dados.length * 90;
                var bottomWidth = 1 / 2.5;
            }

            var options = {
                chart: {
                    width: 360,
                    /*height: 600,*/
                    height: height,
                    bottomWidth: bottomWidth,
                    bottomPinch: 0,
                    inverted: false,
                    animate: 0,
                    curve: {
                        enabled: true,
                        height: 45,
                    },
                    totalCount: null,
                },
                block: {
                    dynamicHeight: false,
                    dynamicSlope: false,
                    barOverlay: false,
                    fill: {
                        //scale: scaleOrdinal(schemeCategory10).domain(range(0, 10)),
                        type: 'gradient',
                    },
                    minHeight: 100,
                    highlight: false,
                },
                label: {
                    enabled: true,
                    fontFamily: null,
                    fontSize: '14px',
                    fill: '#fff',
                    format: '{f} negociações',
                },
                tooltip: {
                    enabled: true,
                    format: '{l}',
                },
                events: {
                    click: {
                        //block: $location.url('/negocios/funilpro')
                        block: null
                    },
                },

            };

            const chart = new D3Funnel('#funnel');
            d3.select('#funnel').selectAll('svg').remove();
            chart.destroy();
            chart.draw(data, options);
        }
        /*NOVO FUNIL DE VENDAS COM ETAPAS DINÂMICAS --- FIM */


        /*MÚLTIPLOS FUNIS DE VENDA --- INÍCIO*/
        vm.listarFunisVendas = function() {
            vm.funisVenda = [];
            loadFunisVenda().then(function(response) {
                $timeout(function() {
                    vm.funisVenda = response;
                    vm.negocios.funv_id = response[0].funv_id;
                    vm.funil_venda_grafico = response[0].funv_id;
                    vm.listarEtapasFunil();
                });
            });
        };
        /*MÚLTIPLOS FUNIS DE VENDA --- FIM*/

        // TURMAS VENDAS COMPLEXAS - A9 --- INÍCIO
        vm.buscaTurmasVC = function () {
            vm.items = loadSaas(Cookies.get('crm_funil_hash')); // carregar saas id
            vm.items.then(function (items) { // ler array de retorno
                vm.saasid = items;
                var dados = {
                    'saasid': vm.saasid,
                };
                turmasVCService.findAll(dados).then(function (response) {
                    if (response.data != 0) {
                        $timeout(function () {
                            vm.turmasVCRepeat = response.data;
                        });
                    } else {
                        $timeout(function () {
                            vm.turmasVCRepeat = '';
                        });
                    }
                }, function (error) {
                    $log.error(error);
                });
            });
        };
        // TURMAS VENDAS COMPLEXAS - A9 --- FIM

    }

})();